import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { GoTriangleDown, GoTriangleUp } from 'react-icons/go';
import dynamic from 'next/dynamic';
import SBAnalyticsTabs, { ITab } from './SBAnalyticsTabs';
import { ITableColumn, SortTable } from '../../Common/Table';
import { max, sortBy, uniq } from 'lodash';
import { ApexOptions } from 'apexcharts';
import {
  formattedNumber,
  formatDurationFromMiliSeconds
} from '../../../../utils/number';
import { EditPencilIcon, InfoIcon } from '../../Common/HubIcons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IStorybookPage } from '../../../../interfaces';
import { StoryBookContext } from '../SBContextContainer';

import moment from 'moment';
import { tz } from 'moment-timezone';
import { Country } from 'country-state-city';
import {
  mapAndSortViewsDensity,
  getAreaChartTooltip,
  interactionStyles,
  CHART_TOTAL_COLOR,
  getChartColorByIndex,
  StatsValueType
} from './helpers';
import { useHorizontalScroll } from '../../../../components/hooks/scroll';
import ImageWithWebp from '../../Common/ImageWithWebp';
import { getSceneByIdForCheck } from '../../../../clientSideServices/scene';
import { mapSceneToStorybook } from '../../../../mappers/storybook';
import EditStatsModal from './EditStatsModal';

const Chart = dynamic(() => import('react-apexcharts'), { ssr: false });
export const StatisticBlock = ({
  name,
  value1,
  value2,
  increase,
  percent,
  sub,
  conversion,
  onEdit,
  hideZero
}) => {
  return (
    <>
      {!sub && (
        <div className="statistic-percent">
          {typeof percent === 'number' ? formattedNumber(percent, '0%') : ''}
        </div>
      )}
      {!!sub && <div className="statistic-percent">{sub}</div>}
      <div
        className={`statistic-items ${conversion ? 'conversion-items' : ''}`}
      >
        <div className="statistic-block">
          <div className="statistic-name">{name}</div>
          <div className="statistic-value1">
            {formattedNumber(value1, conversion && value1 > 0 ? '0.00' : '0 a')}
            {conversion ? '%' : ''}
          </div>
          <div className="statistic-value2">
            {formattedNumber(value2, conversion && value2 > 0 ? '0.00' : '0 a')}
            {conversion ? '%' : ''}
          </div>
          <div
            className="statistic-increase"
            data-zero={increase === 0}
            data-negative={increase < 0}
          >
            {increase > 0 ? (
              <GoTriangleUp />
            ) : increase < 0 ? (
              <GoTriangleDown />
            ) : (
              ''
            )}
            {formattedNumber(increase, '0.00%')}
          </div>
          <div className="percent-bar">
            <div className="percent-value"></div>
          </div>
          {onEdit && (
            <button className="btn edit-button" onClick={onEdit}>
              <EditPencilIcon />
            </button>
          )}
        </div>
      </div>
      <style jsx>{`
        .statistic-items {
          display: flex;
          margin-bottom: 20px;
        }
        .statistic-percent {
          color: rgba(0, 0, 0, 0.5);
          font-size: 17px;
          font-weight: 400;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 10px;
        }
        .statistic-block {
          border: 1px solid #8f6599;
          border-radius: 10px;
          border-top-right-radius: ${percent === 100 ||
          (percent === 0 && !hideZero)
            ? '0'
            : '10px'};
          border-bottom-right-radius: ${percent === 0
            ? '10px'
            : percent > 0
            ? '0px'
            : '10px'};
          background: #fff;
          box-shadow: 5px 5px 25px 0px rgba(0, 0, 0, 0.05);
          width: 180px;
          height: 162px;
          flex-shrink: 0;
          padding: 15px;
          padding-right: 30px;
          position: relative;
        }
        .percent-bar {
          width: 20px;
          position: absolute;
          right: -1px;
          top: 0px;
          height: 100%;
          background: transparent;
          transform: rotate(180deg);
        }
        .percent-value {
          width: 100%;
          --percent: ${percent === 0 && !hideZero ? 100 : percent * 100}%;
          height: var(--percent);
          max-height: 100%;
          background-color: #8f6599;
        }
        .statistic-name {
          color: #b5b5b5;
          font-size: 16px;
          font-weight: 400;
          text-align: left;
        }
        .statistic-value1 {
          color: #252525;
          text-align: right;
          font-size: 30px;
          font-weight: 700;
        }
        .statistic-value2 {
          color: rgba(0, 0, 0, 0.33);
          text-align: right;
          font-size: 20px;
          font-weight: 400;
        }
        .statistic-increase {
          color: #00ab26;
          font-size: 14px;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: right;
        }
        .statistic-increase[data-negative='true'] {
          color: #b63b3b;
        }
        .statistic-increase[data-zero='true'] {
          color: #767676;
        }
        .conversion-items .statistic-block {
          background: #8f6599;
        }
        .conversion-items .statistic-name,
        .conversion-items .statistic-value1 {
          color: #fff;
        }

        .conversion-items .statistic-value2 {
          color: rgba(255, 255, 255, 0.5);
        }

        .conversion-items .statistic-increase {
          color: #70ff8f;
        }

        :global(.statistic-increase svg) {
          margin-right: 5px;
        }

        .btn.edit-button {
          position: absolute;
          top: -5px;
          right: -10px;
          border-radius: 5px;
          border: 1px solid #8f6599;
          background-color: white;
          padding: 5px;
        }
        @media screen and (max-width: 1700px) {
          .statistic-block {
            width: 150px;
            height: 132px;
          }
          .statistic-name {
            font-size: 14px;
          }
          .statistic-value1 {
            font-size: 24px;
          }
          .statistic-value2 {
            font-size: 16px;
          }
          .statistic-increase {
            font-size: 12px;
          }
          .btn.edit-button {
            right: -8px;
            padding: 2px;
          }
          .btn.edit-button ::global(svg) {
            transform: scale(0.7);
          }
        }
      `}</style>
    </>
  );
};

export interface IStatistic {
  name: string;
  value1: string | number;
  value2: string | number;
  percent?: number;
  increase: number;
  sub?: string;
  conversion?: boolean;
  onEdit?: () => void;
  hideZero?: boolean;
}

type SBInsightOverviewProps = {
  range: number;
  startDate: string;
  endDate: string;
  campaign: string;
  setSelectedItem: (item: any) => void;
  setListItemTab: (item: any[]) => void;
};
const getSumFromDataObject = (obj: { [key: string]: number }) => {
  if (!obj) return 0;
  return Object.values(obj).reduce((acc: number, value) => {
    return acc + value;
  }, 0);
};

const getThumbnailFromPage = (page: IStorybookPage) => {
  try {
    return Object.values(JSON.parse(page.thumbnail))[0];
  } catch (error) {
    return page?.thumbnail;
  }
};

const ClientTimeZone = tz.guess();
const overviewTabs = [
  { id: 'targeted', label: 'targeted' },
  { id: 'delivered', label: 'delivered' },
  { id: 'opened', label: 'opened' },
  { id: 'engaged', label: 'engaged' },
  { id: 'interest', label: 'interest' },
  { id: 'conversion', label: 'conversion' },
  { id: 'totalEyeballTime', label: 'watch time' },
  { id: 'viewers', label: 'Viewers' },
  { id: 'returningViewers', label: 'Returning Viewers' }
];

const sourceTabs = [
  { id: 'source', label: 'Source' },
  { id: 'medium', label: 'Medium' }
];

const defaultOverviewTab = overviewTabs[2];

const SBAnalyticsOverview: React.FC<SBInsightOverviewProps> = (props) => {
  const {
    range,
    startDate,
    endDate,
    setSelectedItem,
    // setListItemTab,
    campaign
  } = props;
  const refHourlyView = useRef(null);
  const { state, getSbInsightOverviewData } = useContext(StoryBookContext);
  const storybookInsightOverview = state.storybookInsightOverview;
  const isLoading = state.storybookInsightOverview?.loading === true;
  const { storybook } = state;
  const [filteredArea, setFilteredArea] = useState([]);
  const [currentSourceTab, setCurrentSourceTab] = useState<ITab>(sourceTabs[0]);
  const [includedScenes, setIncludedScenes] = useState<IStorybookPage[]>(
    storybook.pages || []
  );

  const [editStats, setEditStats] = useState<StatsValueType>(null);

  useEffect(() => {
    loadData();
  }, [storybook, startDate, endDate, range, campaign]);

  const loadData = (refresh = false) => {
    const fromDate =
      range > 0
        ? moment().subtract(range, 'days').format('YYYY-MM-DD')
        : startDate;
    const toDate = range > 0 ? moment().format('YYYY-MM-DD') : endDate;
    getSbInsightOverviewData(
      {
        storybookId: storybook.id,
        startDate: fromDate,
        endDate: toDate,
        campaign: campaign,
        timeZone: ClientTimeZone
      },
      refresh
    );
  };

  useEffect(() => {
    const scenes = storybookInsightOverview.data?.topScenes || [];
    const interactions = storybookInsightOverview.data?.topInteractions || [];
    if (!scenes?.length && !interactions?.length) return;
    const sceneIds = scenes.map((scene) => scene.scene);
    const interactionIds = interactions.map(
      (interaction) => interaction.sceneId
    );
    const promises: Promise<IStorybookPage>[] = [
      ...interactionIds,
      ...sceneIds
    ].map((id) => {
      return new Promise((resolve) => {
        const page = storybook.pages.find((p) => p.id === id);
        if (!page) {
          getSceneByIdForCheck(id).then((res) => {
            console.log('get scene by id', {
              id: id,
              res: res.data
            });
            const sbPage = mapSceneToStorybook(res.data).pages[0];
            resolve(sbPage);
          });
        } else resolve(page);
      });
    });
    Promise.all(promises).then((pages) => {
      setIncludedScenes(pages);
    });
  }, [
    storybookInsightOverview.data?.topScenes,
    storybookInsightOverview.data?.topInteractions
  ]);

  const getCurrentPhase = (statKey: string) => {
    return (
      storybookInsightOverview.data?.overviewData?.[statKey]?.currentPhase || 0
    );
  };

  const getPreviousPhase = (statKey: string) => {
    return (
      storybookInsightOverview.data?.overviewData?.[statKey]?.previousPhase || 0
    );
  };

  const getStateValue = (statKey) => {
    const currentPhase = getCurrentPhase(statKey);
    const prePhase = getPreviousPhase(statKey);
    const increase = prePhase > 0 ? (currentPhase - prePhase) / prePhase : 0;
    if (statKey === 'conversion') {
      const openedCurrentPhase = getCurrentPhase('opened');
      const openedPrePhase = getPreviousPhase('opened');
      return {
        value1: (currentPhase / openedCurrentPhase) * 100,
        value2: (prePhase / openedPrePhase) * 100,
        increase
      };
    }
    return {
      value1: currentPhase,
      value2: prePhase,
      increase
    };
  };

  const scrollRef = useHorizontalScroll<HTMLDivElement>();
  const targetValue = getStateValue('targeted');
  const deliveredValue = getStateValue('delivered');
  const openedValue = getStateValue('opened');
  const engagedValue = getStateValue('engaged');
  const interestValue = getStateValue('interest');
  const conversionValue = getStateValue('conversion');
  const getPercentValue = (value1, value2) => {
    if (value1 === 0) {
      return 0;
    }
    return value2 / value1;
  };
  const data: IStatistic[] = [
    {
      name: 'Targeted',
      ...targetValue,
      onEdit: () => setEditStats(StatsValueType.TARGET)
    },
    {
      name: 'Delivered',
      ...deliveredValue,
      percent: getPercentValue(targetValue.value1, deliveredValue.value1),
      onEdit: () => setEditStats(StatsValueType.DELIVERED)
    },
    {
      name: 'Opened',
      ...openedValue,
      percent: getPercentValue(deliveredValue.value1, openedValue.value1)
    },
    {
      name: 'Engaged',
      ...engagedValue,
      percent: getPercentValue(openedValue.value1, engagedValue.value1)
    },
    {
      name: 'Interest',
      ...interestValue,
      percent: getPercentValue(engagedValue.value1, interestValue.value1),
      hideZero: true
    },
    {
      name: 'Conversion',
      ...conversionValue,
      conversion: true,
      sub: '=',
      onEdit: () => setEditStats(StatsValueType.CONVERSION)
    }
  ];

  const [currentOverviewTab, setCurrentOverviewTab] =
    useState<ITab>(defaultOverviewTab);

  const datetimeList = sortBy(
    uniq(
      Object.values(
        storybookInsightOverview.data?.dataByDateAndCountry?.opened || {}
      ).flatMap((countryData) => Object.keys(countryData))
    ),
    (d) => moment(d).unix()
  );

  const countriesWithColor = useMemo(() => {
    return sortBy(
      uniq(
        Object.values(
          storybookInsightOverview.data?.dataByDateAndCountry || {}
        ).flatMap((dataByDateAndCountry) => Object.keys(dataByDateAndCountry))
      )
    ).reduce((colors, country, index) => {
      return {
        ...colors,
        [country]: getChartColorByIndex(index)
      };
    }, {});
  }, [storybookInsightOverview.data?.dataByDateAndCountry]);

  const chartAreaCountrySeries = Object.entries(
    storybookInsightOverview.data?.dataByDateAndCountry?.[
      currentOverviewTab.id
    ] || {}
  ).map(([country, countryData], index) => {
    return {
      name: Country.getCountryByCode(country)?.name || country,
      data: datetimeList.map((datetime) => {
        return countryData[datetime] || 0;
      }),
      color: countriesWithColor[country] || getChartColorByIndex(index)
    };
  });

  const chartAreaTotalLine = {
    name: 'Total',
    data: datetimeList.map((_, index) => {
      const total = chartAreaCountrySeries.reduce((acc, series) => {
        return acc + series.data[index];
      }, 0);
      return total;
    }),
    color: CHART_TOTAL_COLOR
  };

  const chartAreaOptions: ApexOptions = {
    chart: {
      type: 'area',
      toolbar: {
        show: false
      }
    },
    stroke: {
      curve: 'smooth',
      width: 1
    },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      }
    },
    legend: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: datetimeList.map((datetime) =>
        moment(datetime).format('DD MMM')
      )
    },
    yaxis: {
      min: 0,
      max: max(chartAreaTotalLine.data) + 1,
      labels: {
        formatter: (v) => {
          if (currentOverviewTab.id === 'totalEyeballTime')
            return formatDurationFromMiliSeconds(v);
          return formattedNumber(v, '0 a');
        }
      },
      forceNiceScale: currentOverviewTab.id === 'totalEyeballTime'
    },
    tooltip: {
      x: {
        format: 'dd MMM yyyy'
      },
      custom: (params) => getAreaChartTooltip(params, currentOverviewTab)
    },
    noData: {
      text: 'No data to display',
      align: 'center',
      verticalAlign: 'middle'
    }
  };
  const chartAreaSeries = [chartAreaTotalLine, ...chartAreaCountrySeries];

  const filteredChartAreaSeries = useMemo(() => {
    return chartAreaSeries.filter((series) => {
      const countryName = series.name;
      return !filteredArea.includes(countryName);
    });
  }, [filteredArea, chartAreaSeries]);

  const countStatValue = (statKey, country) => {
    const countryData =
      storybookInsightOverview.data?.dataByDateAndCountry?.[statKey]?.[country];

    return datetimeList.reduce((acc, datetime) => {
      return acc + (countryData?.[datetime] || 0);
    }, 0);
  };

  const dataColumns: ITableColumn[] = [
    {
      key: 'localisation',
      title: 'Localisation',
      align: 'left'
    },
    {
      key: 'targeted',
      title: 'Targeted',
      align: 'center',
      custom: (value) => formattedNumber(value)
    },
    {
      key: 'delivered',
      title: 'Delivered',
      align: 'center',
      custom: (value) => formattedNumber(value)
    },
    {
      key: 'opened',
      title: 'Opened',
      align: 'center',
      custom: (value) => formattedNumber(value)
    },
    {
      key: 'engaged',
      title: 'Engaged',
      align: 'center',
      custom: (value) => formattedNumber(value)
    },
    {
      key: 'interest',
      title: 'Interest',
      align: 'center',
      custom: (value) => formattedNumber(value)
    },
    {
      key: 'conversion',
      title: 'Conversion',
      align: 'center'
    },
    {
      key: 'watch_time',
      title: 'Watch Time',
      align: 'center',
      width: '160px',
      custom: (value) => formatDurationFromMiliSeconds(value)
    },
    {
      key: 'viewers',
      title: 'Viewers',
      align: 'center',
      width: '140px',
      custom: (value) => formattedNumber(value)
    },
    {
      key: 'returningViewers',
      title: 'Returning Viewers',
      align: 'center',
      width: '160px',
      custom: (value) => formattedNumber(value)
    }
  ];

  const aggregatedCountry = Object.entries(countriesWithColor || {}).map(
    ([country, color]) => {
      return {
        id: country,
        localisation: Country.getCountryByCode(country)?.name || country,
        styles: {
          color,
          fontWeight: 600
        },
        stylesColumns: [0],
        targeted: countStatValue('targeted', country),
        delivered: countStatValue('delivered', country),
        opened: countStatValue('opened', country),
        engaged: countStatValue('engaged', country),
        conversion: countStatValue('conversion', country),
        interest: countStatValue('interest', country),
        watch_time: countStatValue('totalEyeballTime', country),
        viewers: countStatValue('viewers', country),
        returningViewers: countStatValue('returningViewers', country)
      };
    }
  );

  const totalRow = {
    id: 'total',
    localisation: 'Total',
    styles: {
      color: CHART_TOTAL_COLOR,
      fontWeight: 600
    },
    stylesColumns: [0],
    targeted: aggregatedCountry.reduce((acc, country) => {
      return acc + country.targeted;
    }, 0),
    delivered: aggregatedCountry.reduce((acc, country) => {
      return acc + country.delivered;
    }, 0),
    opened: aggregatedCountry.reduce((acc, country) => {
      return acc + country.opened;
    }, 0),
    engaged: aggregatedCountry.reduce((acc, country) => {
      return acc + country.engaged;
    }, 0),
    conversion: aggregatedCountry.reduce((acc, country) => {
      return acc + country.conversion;
    }, 0),
    interest: aggregatedCountry.reduce((acc, country) => {
      return acc + country.interest;
    }, 0),
    watch_time: aggregatedCountry.reduce((acc, country) => {
      return acc + country.watch_time;
    }, 0),
    viewers: aggregatedCountry.reduce((acc, country) => {
      return acc + country.viewers;
    }, 0),
    returningViewers: aggregatedCountry.reduce((acc, country) => {
      return acc + country.returningViewers;
    }, 0)
  };

  const aggregatedData = [totalRow, ...aggregatedCountry];

  const chartColumnsOptions: ApexOptions = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false
      }
    },
    colors: ['#502B5A'],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '28px',
        dataLabels: {
          position: 'top'
        }
      }
    },
    dataLabels: {
      enabled: true,
      offsetY: -50,
      background: {
        borderColor: '#fff',
        borderWidth: 1,
        enabled: true,
        foreColor: '#fff',
        borderRadius: 5,
        padding: 5
      },
      style: {
        colors: ['#615E83'],
        fontWeight: 400
      }
    },
    grid: {
      show: false
    },
    yaxis: {
      show: false
    },
    xaxis: {
      categories: Object.keys(
        storybookInsightOverview?.data?.deviceDistribution || {}
      ),
      axisTicks: {
        show: false
      },
      axisBorder: {
        show: false
      }
    },
    noData: {
      text: 'No Device Distributions Found',
      align: 'center',
      verticalAlign: 'middle'
    }
  };

  const chartColumnsSeries = [
    {
      name: 'Device Distribution',
      data: Object.values(
        storybookInsightOverview.data?.deviceDistribution || {}
      ).map((data) => getSumFromDataObject(data))
    }
  ];

  const sourceChartBarOptions: ApexOptions = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false
      }
    },
    colors: ['#615E83'],
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '28px',
        dataLabels: {
          position: 'top'
        }
      }
    },
    dataLabels: {
      enabled: true,
      offsetX: -10,
      background: {
        borderColor: '#fff',
        borderWidth: 1,
        enabled: true,
        foreColor: '#fff',
        borderRadius: 5,
        padding: 5
      },
      style: {
        colors: ['#615E83'],
        fontWeight: 400
      }
    },
    grid: {
      show: false
    },
    xaxis: {
      axisTicks: {
        show: false
      },
      axisBorder: {
        show: false
      },
      labels: {
        show: false
      }
    },
    noData: {
      text: `No ${currentSourceTab.label} Found`,
      align: 'center',
      verticalAlign: 'middle'
    }
  };

  const sourceChartBarSeries = useMemo(
    () => [
      {
        name: currentSourceTab.label,
        data: sortBy(
          Object.entries(
            storybookInsightOverview.data?.[currentSourceTab.id] || {}
          ).map(([category, value]: [string, Record<string, number>]) => {
            return {
              x: category,
              y: getSumFromDataObject(value)
            };
          }),
          (s) => s.y * -1
        )
      }
    ],
    [storybookInsightOverview.data, currentSourceTab]
  );

  const hourlyViewSeries = useMemo(() => {
    const density = mapAndSortViewsDensity(
      storybookInsightOverview.data?.viewsDensity,
      startDate,
      endDate,
      range
    );
    return density;
  }, [storybookInsightOverview.data?.viewsDensity, startDate, endDate]);

  const maxHourlyView = useMemo(() => {
    const maxHour = max(
      hourlyViewSeries?.map((hour) => max(hour?.data)) || [0]
    );
    return maxHour + (10 - (maxHour % 10));
  }, [hourlyViewSeries]);

  const hourlyViewOptions: ApexOptions = {
    chart: {
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      heatmap: {
        colorScale: {
          ranges: [
            {
              from: 0,
              to: 0,
              color: '#FAF1FD',
              name: 'low'
            },
            {
              from: maxHourlyView,
              to: maxHourlyView,
              color: '#502B5A',
              name: 'high'
            }
          ]
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    colors: ['#AE3BCD'],
    xaxis: {
      position: 'top',
      labels: {
        formatter: (value) => `${parseInt(value) - 1}`,
        style: {
          colors: ['#615E83'],
          fontSize: '14px'
        }
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: ['#615E83'],
          fontSize: '14px',
          cssClass: 'heatmap-yaxis-label'
        }
      }
    },
    tooltip: {
      enabled: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const value = series[seriesIndex][dataPointIndex];
        const time = `0${dataPointIndex}:00`.slice(-5);
        const label = `${w.globals.seriesNames[seriesIndex]}, ${time}`;
        return `
        <div class="custom-tooltip">
          <div class="tooltip-label">${label}</div>
          <div class="tooltip-value">${value}</div>
        </div>
      `;
      },
      style: {
        fontSize: '12px',
        fontFamily: undefined
      }
    }
  };

  const heatmapHeight = useMemo(() => {
    const min = 500;
    const range = hourlyViewSeries?.length || 1;
    return Math.max(min, range * 20);
  }, [hourlyViewSeries]);

  const topSceneColumns: ITableColumn[] = [
    {
      key: 'scene',
      title: 'Scene',
      align: 'left',
      custom: (value, rowIndex) => {
        return (
          <div className="custom-topview scenes_order">
            <span>{rowIndex + 1}</span>
            <ImageWithWebp src={value} />
          </div>
        );
      }
    },
    {
      key: 'views',
      title: 'Views',
      align: 'center',
      custom: (value) => formattedNumber(value)
    },
    {
      key: 'uniqueViews',
      title: 'Viewers',
      align: 'center',
      custom: (value) => formattedNumber(value)
    },
    {
      key: 'ats',
      title: (
        <OverlayTrigger
          placement="top"
          delay={{ show: 200, hide: 200 }}
          overlay={(props) => (
            <Tooltip
              id="tooltip-info"
              {...props}
              style={{
                color: '#fff',
                fontSize: 12,
                borderRadius: 5,
                textAlign: 'center',
                ...props.style
              }}
            >
              Average Time Spent
            </Tooltip>
          )}
        >
          <div className="title-info">
            ATS <InfoIcon />
          </div>
        </OverlayTrigger>
      ),
      align: 'center',
      custom: (value) => formatDurationFromMiliSeconds(value)
    }
  ];

  const topScenes = useMemo(
    () =>
      storybookInsightOverview.data?.topScenes?.map((scene) => {
        const page = includedScenes.find((page) => page.id === scene.scene);
        return {
          scene: getThumbnailFromPage(page),
          ats: scene.averageEyeballTime,
          views: scene.totalViews,
          uniqueViews: scene.uniqueViews,
          key: page?.name,
          name: page?.name,
          id: scene.scene
        };
      }) || [],
    [storybookInsightOverview.data?.topScenes, includedScenes]
  );

  const topInteractionColumns: ITableColumn[] = [
    {
      key: 'thumbnail',
      title: 'Scene',
      align: 'left',
      custom: (value, rowIndex) => (
        <div className="custom-topview scenes_order">
          <span>{rowIndex + 1}</span>
          <ImageWithWebp src={value} />
        </div>
      )
    },
    {
      key: 'interaction',
      title: 'Interaction',
      align: 'left',
      width: '30%',
      custom: (value) => {
        const vType = value.type?.toLocaleLowerCase?.();
        const color = interactionStyles[vType]?.color;
        return (
          <div className="custom-interaction">
            <div className="value">
              <span>{value.title}</span>
              <span className="type" style={{ color }}>
                {vType}
              </span>
            </div>
          </div>
        );
      }
    },
    {
      key: 'clicks',
      title: 'Clicks',
      align: 'center',
      custom: (value) => formattedNumber(value)
    },
    {
      key: 'viewers',
      title: 'Viewers',
      align: 'center',
      custom: (value) => formattedNumber(value)
    }
  ];

  const topInteractions = useMemo(() => {
    return storybookInsightOverview.data?.topInteractions?.map(
      (interaction, index) => {
        const page = includedScenes.find(
          (page) => page.id === interaction.sceneId
        );
        const title = page?.name;
        const thumbnail = getThumbnailFromPage(page);
        return {
          interaction: {
            title: interaction.title || 'Interaction ' + (index + 1),
            type: interaction.type
          },
          key: title,
          sceneName: title,
          title: interaction.title,
          thumbnail,
          clicks: interaction.totalClicks,
          viewers: interaction.userCount,
          sceneId: interaction.sceneId
        };
      }
    );
  }, [storybookInsightOverview.data?.topInteractions, includedScenes]);

  const toggleAreaSeries = (row) => {
    const isChecked = filteredArea.includes(row.localisation);
    if (isChecked) {
      setFilteredArea(filteredArea.filter((area) => area !== row.localisation));
    } else {
      setFilteredArea([...filteredArea, row.localisation]);
    }
  };

  const handleSelectItem = (item) => {
    if (item.type === 'scene') {
      setSelectedItem({ scene: item.id, name: item.name, type: 'scene' });
      return;
    }
    setSelectedItem({
      scene: item.sceneId,
      name: item.sceneName,
      type: 'scene'
    });
  };

  const legendNumbers = useMemo(() => {
    const numbers = [];
    for (let i = 0; i <= maxHourlyView; i += maxHourlyView / 5) {
      numbers.push(
        <span key={i} style={{ left: `${(i / maxHourlyView) * 100}` }}>
          {formattedNumber(i)}
        </span>
      );
    }
    return numbers.reverse();
  }, [maxHourlyView]);

  const xAxisNumbers = useMemo(() => {
    return new Array(24)
      .fill(0)
      .map((_, index) => <span key={index}>{index}</span>);
  }, []);

  return (
    <>
      <div className="analytics-main" data-isloading={isLoading}>
        <button
          className="btn-round btn btn-dark btn-refresh"
          onClick={() => loadData(true)}
        >
          Refresh
        </button>
        <div className="overview-statistic" ref={scrollRef}>
          {data.map((item, index) => (
            <StatisticBlock
              key={index}
              name={item.name}
              value1={item.value1}
              value2={item.value2}
              increase={item.increase}
              percent={item.percent}
              sub={item?.sub}
              conversion={item?.conversion}
              onEdit={item.onEdit}
              hideZero={item?.hideZero}
            />
          ))}
        </div>
        <div className="overview-linechart card-section">
          <SBAnalyticsTabs
            dot={true}
            onSelectTab={setCurrentOverviewTab}
            tabs={overviewTabs}
            active={currentOverviewTab}
          />
          <Chart
            options={chartAreaOptions}
            series={filteredChartAreaSeries}
            type="area"
            height="350"
          />
        </div>
        <div className="overview-table card-section">
          <SortTable
            rowCheckbox
            onCheckboxClick={toggleAreaSeries}
            columns={dataColumns}
            sorts={[
              'targeted',
              'delivered',
              'opened',
              'engaged',
              'interest',
              'conversion',
              'watch_time',
              'unique_user',
              'returning_user'
            ]}
            data={aggregatedData}
            onlyActiveSortBy
            defaultChecked
          />
        </div>
        <div className="overview-bar">
          <div className="card-section">
            <label>Device Distribution</label>
            <Chart
              options={chartColumnsOptions}
              series={chartColumnsSeries}
              height="350"
              type="bar"
            />
          </div>
          <div className="card-section">
            <SBAnalyticsTabs
              dot={true}
              onSelectTab={setCurrentSourceTab}
              tabs={sourceTabs}
              active={currentSourceTab}
            />
            <Chart
              options={sourceChartBarOptions}
              series={sourceChartBarSeries}
              height="350"
              type="bar"
            />
          </div>
        </div>
        <div className="overview-heatmap">
          <div className="card-section">
            <label>
              Global Hourly Views Density (UTC +8)
              <OverlayTrigger
                placement="top"
                delay={{ show: 200, hide: 200 }}
                overlay={(props) => (
                  <Tooltip
                    id="tooltip"
                    {...props}
                    style={{
                      color: '#fff',
                      fontSize: 12,
                      borderRadius: 5,
                      textAlign: 'center',
                      ...props.style
                    }}
                  >
                    This heatmap represents hourly views from audiences base on
                    your localisation selection, aggregated and visualized for
                    each hour of the day. Please note that the data is
                    normalized and displayed in your defined browser timezone.
                  </Tooltip>
                )}
              >
                <div style={{ display: 'flex' }} ref={refHourlyView}>
                  <InfoIcon />
                </div>
              </OverlayTrigger>
            </label>
            <div className="heatmap-content">
              <div className="heatmap-chart">
                <div className="heatmap-wrapper">
                  <Chart
                    options={hourlyViewOptions}
                    series={hourlyViewSeries}
                    height={heatmapHeight}
                    type="heatmap"
                  />
                </div>
                <div className="custom-heatmap-xasis">{xAxisNumbers}</div>
              </div>
              <div className="custom-heatmap-legend">
                <div className="legend-bar"></div>
                <div className="legend-number">{legendNumbers}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="overview-top-view">
          <div className="card-section">
            <div className="overview__top-scene">
              <label>Top scene</label>
              <SortTable
                columns={topSceneColumns}
                onRowSelect={(row) =>
                  handleSelectItem({ ...row, type: 'scene' })
                }
                data={topScenes}
                first={10}
                sorts={['views', 'uniqueViews', 'ats']}
                onlyActiveSortBy
                noData={{
                  text: `No Top Scenes Found`,
                  align: 'center',
                  verticalAlign: 'middle'
                }}
              />
            </div>
          </div>
          <div className="card-section">
            <div>
              <label>Top interaction</label>
              <SortTable
                columns={topInteractionColumns}
                data={topInteractions}
                onRowSelect={(row) =>
                  handleSelectItem({ ...row, type: 'interaction' })
                }
                first={10}
                sorts={['clicks', 'viewers']}
                onlyActiveSortBy
                noData={{
                  text: `No Top Interactions Found`,
                  align: 'center',
                  verticalAlign: 'middle'
                }}
              />
            </div>
          </div>
        </div>
        <div className="overlay">
          <span className="spinner-border spinner-border-lg" />
        </div>
      </div>
      <EditStatsModal
        type={editStats}
        enabled={editStats !== null}
        onHide={() => setEditStats(null)}
        storybookId={storybook.id}
        key={editStats}
      />

      <style jsx>{`
        .overview-top-view .card-section {
          max-height: 1000px;
          overflow-y: auto;
        }
        .apexcharts-legend apexcharts-align-center apx-legend-position-top {
          display: none;
        }
        .overview-statistic {
          margin: 20px auto;
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: space-between;
          max-width: 1670px;
          padding: 5px 20px;
          overflow-x: auto;
        }

        .btn-refresh {
          position: absolute;
          top: 25px;
          right: 25px;
          width: 120px;
        }

        .overview-linechart :global(.analytics-tab) {
          padding: 7px 10px !important;
          font-size: 14px !important;
          text-transform: capitalize;
        }
        .overview-linechart :global(.analytics-tabs) {
          border-bottom: none;
        }

        :global(.overview-linechart .active-tab) {
          color: rgba(0, 0, 0, 0.9);
        }
        :global(.overview-linechart .active-tab svg) {
          color: #4c2d5a;
        }

        :global(.title-info svg) {
          width: 12px;
          margin-left: 5px;
        }

        :global(.title-info) {
          display: flex;
          align-items: center;
        }
        .card-section {
          border-radius: 10px;
          background: #fff;
          box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.08);
          padding: 20px;
          margin-bottom: 20px;
        }
        .card-section label {
          color: rgba(0, 0, 0, 0.5);
          font-size: 16px;
          font-weight: 400;
        }
        .overview-bar,
        .overview-top-view {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 15px;
        }
        .overview-heatmap label {
          display: flex;
          align-items: center;
        }
        :global(.overview-heatmap label svg) {
          width: 18px;
          margin-left: 5px;
        }
        :global(.overview-heatmap .custom-tooltip) {
          padding: 10px;
          color: #4c2d5a;
        }

        :global(.overview-heatmap .tooltip-label) {
          font-size: 12px;
        }
        :global(
            .overview-heatmap
              .apexcharts-legend.apexcharts-align-center.apx-legend-position-top
          ) {
          display: none;
        }
        :global(.overview-heatmap .tooltip-value) {
          font-weight: 500;
          font-size: 18px;
        }
        :global(.custom-topview img) {
          width: 90px;
          height: auto;
          margin-left: 20px;
          border: 1px solid #ccc;
        }

        :global(.overview-top-view .sort-table thead tr) {
          border-bottom: none;
        }
        :global(.overview-top-view .sort-table tbody td) {
          height: 85px;
          font-weight: 500;
          color: #252525;
        }
        .overview__view-more {
          text-align: center;
          color: #4c2d5a;
          cursor: pointer;
        }
        :global(.tooltip-inner) {
          background-color: #fff;
          box-shadow: 0px 2.679px 6.698px 0px rgba(0, 0, 0, 0.25);
          color: #484848;
          font-size: 12px;
          font-weight: 400;
          text-align: left;
          padding: 0.5rem 0.75rem;
          max-width: 230px;
        }
        .heatmap-content {
          display: flex;
          width: 100%;
          overflow: hidden;
          gap: 15px;
        }
        .heatmap-chart {
          flex: 1;
        }
        .heatmap-content .heatmap-wrapper {
          height: 520px;
          overflow-y: auto;
          overflow-x: hidden;
          z-index: 1;
          position: relative;
        }

        .heatmap-content .heatmap-wrapper::-webkit-scrollbar {
          width: 5px;
        }

        .heatmap-content .heatmap-wrapper::-webkit-scrollbar-thumb {
          background: #502b5a;
          border: 3px solid #502b5a;
          border-radius: 5px;
        }

        .custom-heatmap-legend {
          padding-top: 30px;
          display: flex;
        }
        .custom-heatmap-legend .legend-bar {
          width: 20px;
          height: 90%;
          background: linear-gradient(
            180deg,
            #502b5a 0%,
            #ae3bcd 50%,
            #faf1fd 100%
          );
          flex-shrink: 0;
        }
        .custom-heatmap-legend .legend-number {
          height: 90%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding-left: 10px;
          font-size: 12px;
        }
        :global(.arrow) {
          display: none !important;
        }
        @media (max-width: 1080px) {
          .overview-top-view {
            display: grid;
            grid-template-columns: 1fr;
          }
          .overview-bar {
            display: grid;
            grid-template-columns: 1fr;
          }
        }
        .analytics-main[data-isloading='true'] {
          opacity: 0.5;
          pointer-events: none;
          position: relative;
        }

        .overlay {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          display: none;
          padding-top: 30px;
          justify-content: center;
        }
        .heatmap-chart :global(.apexcharts-xaxis) {
          opacity: 0;
        }

        .custom-heatmap-xasis {
          display: flex;
          justify-content: space-between;
          margin-top: 10px;
          font-size: 12px;
          width: 100%;
          padding: 0px 10px 0px 73px;
          font-weight: 500;
          margin-top: -20px;
          background: #fff;
          z-index: 2;
          position: relative;
        }

        .analytics-main[data-isloading='true'] .overlay {
          display: flex;
        }
      `}</style>
    </>
  );
};

export default SBAnalyticsOverview;
