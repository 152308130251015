import { cloneDeep } from 'lodash';
import React, { MouseEventHandler, useEffect } from 'react';
import { Spinner, Table } from 'react-bootstrap';
import { TiArrowSortedUp, TiArrowSortedDown } from 'react-icons/ti';
import { HiOutlineX } from 'react-icons/hi';
import { BsThreeDots } from 'react-icons/bs';

export type ISBAnalyticsSortType = 'asc' | 'des';
export type TAlign = 'left' | 'center' | 'right';
export interface ITableColumn {
  title: any;
  key: string;
  align?: TAlign;
  width?: string;
  custom?: (value, rowIndex) => void;
}

export const TableCheckbox = ({
  checked = false,
  color,
  onCheckboxClick
}: {
  checked: boolean;
  color?: string;
  onCheckboxClick: MouseEventHandler<HTMLDivElement>;
}) => {
  return (
    <>
      <div className="table-checkbox" onClick={onCheckboxClick}>
        {checked && (
          <div className="checkbox__check">
            <HiOutlineX size={18} />
          </div>
        )}
      </div>
      <style jsx>{`
        .table-checkbox {
          width: 20px;
          height: 20px;
          border: 2px solid ${color || '#ccc'};
          cursor: pointer;
          display: flex;
          margin-right: 10px;
          align-items: center;
          justify-content: center;
        }
      `}</style>
    </>
  );
};
export const SortTable = ({
  columns,
  data,
  sorts = [],
  rowCheckbox = false,
  onCheckboxClick,
  onRowSelect,
  onlyActiveSortBy = false,
  first,
  defaultChecked,
  noData,
  stripped,
  optionBtn,
  onOptionBtnClick,
  loading = false
}: {
  columns: ITableColumn[];
  data: any[];
  sorts?: string[];
  rowCheckbox?: boolean;
  onCheckboxClick?: MouseEventHandler<HTMLDivElement>;
  onRowSelect?: MouseEventHandler<HTMLDivElement>;
  onlyActiveSortBy?: boolean;
  first?: number;
  defaultChecked?: boolean;
  noData?: {
    text: string;
    align?: 'left' | 'center' | 'right';
    verticalAlign?: 'top' | 'middle' | 'bottom';
    padding?: string;
  };
  stripped?: boolean;
  optionBtn?: React.ReactNode;
  onOptionBtnClick?: (row: any) => void;
  loading?: boolean;
}) => {
  const [sortBy, setSortBy] = React.useState('');
  const [sortType, setSortType] = React.useState<ISBAnalyticsSortType>('asc');
  const [dataTable, setDataTable] = React.useState([]);
  const [showAll, setShowAll] = React.useState(first ? false : true);
  const [checkedRow, setCheckedRow] = React.useState({});

  useEffect(() => {
    if (data) {
      setDataTable(data);
    }
  }, [data]);

  const handleRowSelect = (row) => {
    if (onRowSelect) {
      onRowSelect(row);
    }
  };

  const handleSort = (sort) => {
    let newSortType: ISBAnalyticsSortType = sortType === 'asc' ? 'des' : 'asc';
    if (sort !== sortBy) {
      newSortType = 'asc';
    }
    const sortableItems = cloneDeep(data);
    if (newSortType === 'asc') {
      sortableItems.sort((a, b) => (a[sort] > b[sort] ? 1 : -1));
    } else {
      sortableItems.sort((a, b) => (a[sort] < b[sort] ? 1 : -1));
    }
    setSortBy(sort);
    setSortType(newSortType);
    setDataTable(sortableItems);
  };
  const rows = showAll ? dataTable : dataTable.slice(0, first);
  if (loading)
    return (
      <div className="loading">
        <Spinner animation="border" />
      </div>
    );
  return (
    <>
      <Table responsive borderless className="sort-table">
        <thead style={{ position: 'sticky', top: 0 }}>
          <tr>
            {columns.map((column, index) => (
              <th
                key={index}
                onClick={() =>
                  sorts.includes(column.key) ? handleSort(column.key) : null
                }
                style={{
                  textAlign: column.align,
                  width: column.width || 'unset',
                  minWidth: column.width || 'unset',
                  cursor: 'pointer'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: column.align || 'center'
                  }}
                >
                  <span className="table-th">{column.title}</span>
                  {sorts.includes(column.key)
                    ? !onlyActiveSortBy ||
                      (column.key === sortBy &&
                        (sortType === 'des' ? (
                          <TiArrowSortedDown
                            size={16}
                            fill={'rgba(0, 0, 0, 0.5)'}
                            style={{ marginLeft: '5px' }}
                          />
                        ) : (
                          <TiArrowSortedUp
                            size={16}
                            fill={'rgba(0, 0, 0, 0.5)'}
                            style={{ marginLeft: '5px' }}
                          />
                        )))
                    : null}
                </div>
              </th>
            ))}
            {optionBtn && (
              <th style={{ width: '10%', textAlign: 'right' }}></th>
            )}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, rowIndex) => (
            <tr key={row.key || rowIndex} onClick={() => handleRowSelect(row)}>
              {columns.map((column, columnIndex) => {
                const styles =
                  !row.stylesColumns || row.stylesColumns?.includes(columnIndex)
                    ? row.styles || {}
                    : {};
                return (
                  <td
                    style={{
                      ...styles,
                      textAlign: column.align,
                      width: column.width || 'unset',
                      minWidth: column.width || 'unset'
                    }}
                    key={columnIndex}
                  >
                    {columnIndex === 0 && rowCheckbox ? (
                      <div style={{ display: 'flex' }}>
                        <TableCheckbox
                          checked={
                            defaultChecked
                              ? !checkedRow[row.id]
                              : checkedRow[row.id]
                          }
                          onCheckboxClick={() => {
                            setCheckedRow((prev) => ({
                              ...prev,
                              [row.id]: !prev[row.id]
                            }));
                            onCheckboxClick?.(row);
                          }}
                          color={row.styles?.color}
                        />
                        {column.custom
                          ? column.custom(row[column.key], rowIndex)
                          : row[column.key]}
                      </div>
                    ) : column.custom ? (
                      column.custom(row[column.key], rowIndex)
                    ) : (
                      row[column.key]
                    )}
                  </td>
                );
              })}
              {optionBtn && (
                <td style={{ textAlign: 'right' }}>
                  {
                    <button
                      id={row.id || `button-${rowIndex}`}
                      className="btn btn-round btn-dark mr-3 d-inline-flex justify-content-center align-items-center"
                      style={{ padding: 0, height: '30px', width: '30px' }}
                      onClick={() => onOptionBtnClick(row)}
                    >
                      <BsThreeDots />
                    </button>
                  }
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
      {rows?.length === 0 && noData?.text && (
        <div
          style={{
            textAlign: noData?.align || 'center',
            verticalAlign: noData?.verticalAlign || 'middle',
            padding: noData?.padding || '20px 0',
            fontSize: '14px'
          }}
        >
          {noData.text}
        </div>
      )}
      {first && first < data?.length && (
        <div className="view-more">
          <span onClick={() => setShowAll((prev) => !prev)}>
            {' '}
            {showAll ? 'view less' : 'view more'}{' '}
          </span>
        </div>
      )}
      <style jsx>{`
        :global(.sort-table .table-th) {
          color: rgba(0, 0, 0, 0.5);
          font-size: 14px;
          font-weight: 400;
        }
        :global(.sort-table thead tr) {
          border-bottom: 2px solid rgba(0, 0, 0, 0.25);
        }
        :global(.sort-table tbody tr) {
          border-bottom: 2px solid rgba(204, 204, 204, 0.25);
        }
        :global(.sort-table tbody tr:last-child) {
          border-bottom: none;
        }
        :global(.sort-table .table-th svg) {
          color: rgba(0, 0, 0, 0.5);
        }
        :global(.sort-table td) {
          background: #fff !important;
          color: #615e83;
          font-size: 16px;
          font-weight: 400;
          padding: 15px 10px !important;
        }
        :global(.sort-table tr) {
          font-size: 16px;
          font-weight: 400;
        }
        :global(.sort-table tbody tr:nth-child(even) td) {
          background: ${stripped ? '#f1f1f1' : 'unset'} !important;
        }
        .view-more {
          margin: auto;
          display: flex;
          justify-content: center;
        }
        .view-more span {
          cursor: pointer;
        }
        ::global(.table-responsive) {
          overflow-x: unset;
        }
        ::global(.sort-table thead) {
          position: sticky;
          top: 0;
          z-index: 4;
          background: #fff;
        }
      `}</style>
    </>
  );
};
