import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import DatePicker from 'react-datepicker';
import { enGB } from 'date-fns/locale';
import { Button } from 'react-bootstrap';
type CURRENT_PICKER = 'start' | 'end';

const DateRange = ({
  startDate,
  endDate,
  onDateChange,
  defaultOpen = false
}: {
  startDate: number;
  endDate: number;
  onDateChange: (start: number, end: number) => void;
  defaultOpen?: boolean;
}) => {
  const [openDatePicker, setOpenDatePicker] = useState<boolean>(defaultOpen);
  const [current, setCurrent] = useState<CURRENT_PICKER>('start');
  const [start, setStart] = useState(new Date(startDate));
  const [end, setEnd] = useState(new Date(endDate));

  useEffect(() => {
    if (!openDatePicker) resetState();
  }, [openDatePicker]);

  const resetState = () => {
    setOpenDatePicker(false);
    setCurrent('start');
    setStart(new Date(startDate));
    setEnd(new Date(endDate));
  };
  const triggerRef = React.useRef(null);

  const formatDate = (date: Date | number) => {
    return format(date, 'dd/MM/yy').toString();
  };

  return (
    <div className="DateRange" ref={triggerRef}>
      <div
        className="DateRange__value"
        onClick={() => setOpenDatePicker(!openDatePicker)}
      >
        <span>
          {formatDate(startDate)} To {formatDate(endDate)}
        </span>
      </div>
      {openDatePicker && (
        <div className="DateRange__picker">
          <label>Date Range: Custom</label>
          <div className="picker-type">
            <div
              className={`picker-type__item ${
                current === 'start' ? 'picker-type--selected' : ''
              }`}
              onClick={() => setCurrent('start')}
            >
              <div>From</div>
              <div>{formatDate(start)}</div>
            </div>
            <div
              className={`picker-type__item ${
                current === 'end' ? 'picker-type--selected' : ''
              }`}
              onClick={() => setCurrent('end')}
            >
              <div>To</div>
              <div>{formatDate(end)}</div>
            </div>
          </div>
          <div className="calendar-container">
            <DatePicker
              selected={current === 'start' ? start : end}
              onChange={(date: Date) => {
                current === 'start' ? setStart(date) : setEnd(date);
              }}
              inline
              locale={enGB}
              className="calendar"
              useWeekdaysShort={true}
              minDate={current === 'end' ? start : undefined}
            />
          </div>
          <Button
            disabled={
              (formatDate(end) === formatDate(endDate) &&
                formatDate(start) === formatDate(startDate)) ||
              start > end
            }
            className="date-confirm"
            variant="primary"
            size="sm"
            onClick={() => {
              onDateChange(start.getTime(), end.getTime());
              setOpenDatePicker(false);
            }}
          >
            Confirm
          </Button>
        </div>
      )}
      {openDatePicker && (
        <div
          className="backdrop"
          onClick={() => setOpenDatePicker(false)}
        ></div>
      )}
      <style jsx>{`
        .DateRange {
          position: relative;
          z-index: 2;
        }
        .backdrop {
          position: fixed;
          top: 0;
          left: 0;
          width: 100vw;
          height: 100vh;
          background: transparent;
          z-index: 1;
        }
        .DateRange__value {
          border-radius: 8px;
          border: 0.325px solid #a8a8a8;
          width: fit-content;
          display: flex;
          align-items: center;
          height: 22px;
          padding: 0 5px;
          font-size: 12px;
          cursor: pointer;
        }
        .DateRange__picker {
          position: absolute;
          right: -5px;
          top: -1px;
          width: 270px;
          height: auto;
          z-index: 9999;
          background: #fff;
          box-shadow: 0px 2.679px 6.698px 0px rgba(0, 0, 0, 0.25);
          border-radius: 20px;
          padding: 15px 20px;
        }
        .DateRange__picker label {
          color: #c1c1c1;
          font-size: 12.056px;
          font-weight: 600;
        }
        .DateRange__picker .picker-type {
          display: flex;
          justify-content: space-between;
        }
        .DateRange__picker .picker-type__item {
          border-radius: 5px;
          border: 1px solid #a8a8a8;
          color: #707070;
          font-size: 12px;
          padding: 5px 10px;
          width: 100px;
        }
        .DateRange__picker .picker-type--selected {
          border: 1px solid #8f6599;
          background: rgba(143, 101, 153, 0.2);
        }
      `}</style>
      <style jsx global>{`
        .date-confirm {
          background: #8f6599 !important;
          width: 100%;
          border-color: #8f6599 !important;
          font-weight: 500;
        }
        .date-confirm:disabled {
          background: #fff !important;
          border-color: #707070 !important;
          color: #707070;
          opacity: 0.5;
        }
        .calendar-container {
          margin: 10px 0;
        }
        .react-datepicker {
          display: flex;
        }
        .react-datepicker__month-container,
        .react-datepicker__time-container {
          float: none;
        }

        .react-datepicker-wrapper {
          display: block;
          width: 100%;
        }

        .react-datepicker,
        .react-datepicker__month-container {
          width: 100%;
          border: none;
        }

        .react-datepicker__month-container {
          padding: 0;
        }

        .calender,
        .react-datepicker__day-name,
        .react-datepicker__day,
        .react-datepicker__time-name {
          width: 25px;
          height: 25px;
        }
        .react-datepicker {
          border: none;
        }

        .react-datepicker__day-name {
          color: #707070 !important;
        }
        .react-datepicker__day--today {
          background: #ccc !important;
          color: #fff !important;
        }
        .react-datepicker__day {
          color: #707070;
        }
        .react-datepicker__day {
          font-weight: 400 !important;
        }

        .react-datepicker__day-names,
        .react-datepicker__week {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .react-datepicker__day--outside-month {
          font-weight: normal !important;
          opacity: 0.3 !important;
        }

        .react-datepicker__header {
          background-color: #fff;
          border-bottom: none;
        }

        .month-and-year {
          color: #707070 !important;
          text-transform: capitalize !important;
        }
        .react-datepicker__day-name {
          color: #888888;
        }

        .react-datepicker__day {
          display: inline-flex;
          align-items: center;
          text-align: center;
          justify-content: center;
          border-radius: 100%;
        }

        .react-datepicker__day--selected,
        .react-datepicker__day--selected:hover {
          background-color: #8f6599 !important;
          color: #fff;
        }

        .react-datepicker__day:hover {
          border-radius: 100%;
        }

        .react-datepicker__day:focus {
          outline: none;
        }
        .react-datepicker__current-month {
          color: #707070;
          text-align: center;
          font-size: 14px;
          font-weight: 400;
        }
        .react-datepicker__day--disabled {
          color: #ccc;
        }
      `}</style>
    </div>
  );
};

export default DateRange;
