import { first, last, takeRight } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import {
  IStorybook,
  IStorybookPage,
  ITagsVisibilityPayload,
  LoadingStatus,
  SBInsightInteractions,
  SBInsightOverview,
  SBInsightSceneOverview,
  StoryBookLayoutPage
} from '../../../interfaces';
import { getPolotnoJsonFromStorybook } from '../../../mappers/polotno';
import { mapStorybookToPayload } from '../../../mappers/storybook';
import {
  actionHubAlertError,
  actionHubAlertInfo
} from '../../../redux/actions';
import { addUserInfoAsync } from '../../../redux/advisorHubAsyncActions';
import { getIdentityId } from '../../../utils/identity';
import { generateV4UUID } from '../../../utils/identityGenerator';
import { saveEditorJson } from '../../clientSideServices/library';
import {
  defaultStorybookSettings,
  storybookEditHistoryStep
} from '../../config';
import { HubContext } from '../HubContext';
import { SBPreviewType } from './Preview';
import {
  callStorybookService,
  SBActionTarget,
  SBServiceAction,
  SBServiceActionTarget,
  SBServiceActionType,
  SBServicePayloadType
} from './SBActionHandler';
import {
  getSBInsightInteraction,
  getSBInsightSceneOverview,
  getStorybookCampaigns,
  getStoryBookOverview,
  SBInsightInteractionQuery,
  SBInsightSceneOverviewQuery,
  SBOverviewQuery
} from '../../clientSideServices/storybook';

interface LoadingState {
  [key: string]: LoadingStatus;
}

interface SavePublishedStorybookPayload {
  storybook: IStorybook;
  passcode?: string;
}

type UpdateActionPayload<T extends SBActionTarget> =
  T extends SBActionTarget.Storybook
    ? { data: IStorybook; append?: boolean; doNotFlagChange?: boolean }
    : T extends SBActionTarget.StorybookPage
    ? { page: IStorybookPage; index: number; doNotFlagChange?: boolean }
    : T extends SBActionTarget.Storybooks
    ? IStorybook[]
    : any;

type DeleteActionPayload<T extends SBActionTarget> =
  T extends SBActionTarget.Storybook
    ? string
    : T extends SBActionTarget.StorybookPage
    ? number
    : T extends SBActionTarget.PublishedStorybook
    ? IStorybook
    : any;

type SaveActionPayload<T extends SBActionTarget> =
  T extends SBActionTarget.PublishedStorybook
    ? SavePublishedStorybookPayload
    : T extends SBActionTarget.ActivatedStorybook
    ? { publishAfterActivated: boolean }
    : T extends SBActionTarget.StorybookAttributes
    ? ITagsVisibilityPayload
    : null;

type ReadActionPayload<T extends SBActionTarget> =
  T extends SBActionTarget.StorybookOfVersion
    ? { id: string; version: number }
    : T extends SBActionTarget.PublishedStorybook
    ? { id: string; passcode?: string }
    : T extends SBActionTarget.Storybooks
    ? {
        startIndex: number;
        keywords?: string;
        teamIds?: string[];
        excludeMine?: boolean;
      }
    : T extends SBActionTarget.Storybook
    ? { id: string; silent?: boolean }
    : string;

type NavigatePayload<T extends StoryBookLayoutPage> =
  T extends StoryBookLayoutPage.DESIGNER
    ? {
        id?: string;
        initData?: IStorybook;
        newPage?: boolean;
        currentFolder?: string;
      }
    : { id?: string };
export interface StorybookState {
  storybook: IStorybook;
  storybookInsightOverview?: {
    loading: boolean;
    data: SBInsightOverview;
  };
  storybookInsightSceneOverview?: {
    loading: boolean;
    data: SBInsightSceneOverview;
  };
  sbInsightCampaigns?: {
    loading: boolean;
    data: string[];
  };
  storybookInteractions?: {
    loading: boolean;
    data: SBInsightInteractions;
  };
  contentViewer: IStorybook;
  pageHistory: StoryBookLayoutPage[];
  loadingStatus: LoadingState;
  currentPageIndex?: number;
  undoRedo?: {
    currentPointer: number;
    canUndo: boolean;
    canRedo: boolean;
    historyLength: number;
  };
}

export interface StorybookSetState {
  setPageHistory: React.Dispatch<React.SetStateAction<StoryBookLayoutPage[]>>;
  setStorybook: React.Dispatch<React.SetStateAction<IStorybook>>;
  setDataHasChanged: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  setContentViewer: React.Dispatch<React.SetStateAction<IStorybook>>;
  setLoadingStatus: React.Dispatch<React.SetStateAction<LoadingState>>;
  setCurrentPageIndex: React.Dispatch<React.SetStateAction<number | null>>;
  setEditHistory: React.Dispatch<
    React.SetStateAction<{ currentPointer: number; history: IStorybook[] }>
  >;
}
interface StorybookAction {
  read: <T extends SBActionTarget>(
    target: T,
    payload?: ReadActionPayload<T>,
    callback?: any
  ) => any;
  save: <
    T extends
      | SBActionTarget.Storybook
      | SBActionTarget.ActivatedStorybook
      | SBActionTarget.PublishedStorybook
      | SBActionTarget.StorybookAttributes
  >(
    target: T,
    callback?: any,
    payload?: SaveActionPayload<T>
  ) => any;
  update: <T extends SBActionTarget>(
    target: T,
    payload?: UpdateActionPayload<T>
  ) => any;
  delete: <T extends SBActionTarget>(
    target: T,
    payload: DeleteActionPayload<T>,
    callback?: any
  ) => any;
  navigate: <T extends StoryBookLayoutPage>(
    page?: T,
    open?: boolean,
    payload?: NavigatePayload<T>
  ) => any;
  setLayoutMode: (layoutMode: IStorybookLayoutMode) => void;
  openStorybookPage: (index: number) => void;
  resetState: () => void;
  redo: (callback?: any) => void;
  undo: (callback?: any) => void;
  setStorybook: (storybook: IStorybook) => void;
  resetStorybookOverview: () => void;
}

export interface IStorybookLayoutMode {
  popupMode?: boolean;
  viewOnlyMode?: boolean;
  selectMode?: boolean;
  previewMode?: SBPreviewType;
}

interface StorybookEntitlement extends IStorybookLayoutMode {
  allowEdit?: boolean;
  allowSaveDraft?: boolean;
  allowPublish?: boolean;
  allowSortingPage?: boolean;
  allowDeleteStorybook?: boolean;
  allowPreviewStorybook?: boolean;
  shouldShowActivatedOnly?: boolean;
}

interface StorybookUIVisibility {
  showSortButton?: boolean;
  showPublishButton?: boolean;
  showSaveDraftButton?: boolean;
  showDeleteStorybookButton?: boolean;
}

type InsightOverviewQuery =
  | SBOverviewQuery
  | SBInsightSceneOverviewQuery
  | SBInsightInteractionQuery;

export interface IStorybookContext {
  setContentViewer: (contentViewer: IStorybook) => void;
  getSbInsightOverviewData: (params: SBOverviewQuery, force?: boolean) => void;
  getSBInsightSceneOverviewData: (params: SBInsightSceneOverviewQuery) => void;
  getSbInsightInteractionData: (params: SBInsightInteractionQuery) => void;
  state: StorybookState;
  action: StorybookAction;
  entitlement: StorybookEntitlement;
  visibility: StorybookUIVisibility;
  selectedStorybookPage: IStorybookPage;
}
export const StoryBookContext =
  React.createContext<IStorybookContext>(undefined);

const defaultEditHistory = {
  currentPointer: 0,
  history: []
};
const SBContextContainer = ({
  children,
  brandId
}: {
  initialPage?: StoryBookLayoutPage;
  children: React.ReactNode;
  brandId: string;
}) => {
  const dispatch = useDispatch();

  const { brandSetup } = React.useContext(HubContext) || {};
  const userId = getIdentityId() || 'user?.id';
  const [pageHistory, setPageHistory] = React.useState<StoryBookLayoutPage[]>(
    []
  );
  const [storybook, setStorybook] = React.useState<IStorybook>({});
  const [sbInsightOverview, setSBInsightOverview] = React.useState<{
    loading: boolean;
    data: SBInsightOverview;
  }>({ loading: false, data: {} });
  const [sbInsightSceneOverview, setSBInsightSceneOverview] = React.useState<{
    loading: boolean;
    data: SBInsightSceneOverview;
  }>({ loading: false, data: {} });
  const [sbInsightInteractions, setSBInsightInteractions] = React.useState<{
    loading: boolean;
    data: any;
  }>({ loading: false, data: {} });
  const [sbInsightCampaign, setSBInsightCampaign] = React.useState<{
    loading: boolean;
    data: string[];
    storybookId: string;
  }>({ loading: false, data: [], storybookId: '' });

  const [dataHasChanged, setDataHasChanged] = React.useState<
    boolean | undefined
  >(false);
  const [contentViewer, setContentViewer] = React.useState<IStorybook>({});
  const [loadingStatus, setLoadingStatus] = React.useState<LoadingState>({});
  const [layoutMode, setLayoutMode] = React.useState<IStorybookLayoutMode>({});
  const [currentPageIndex, setCurrentPageIndex] = React.useState<number>(null);
  const [editHistory, setEditHistory] = React.useState<{
    currentPointer: number;
    history: IStorybook[];
  }>(defaultEditHistory);

  const updateHistoryPointer = (pointer: number) => {
    setEditHistory({ ...editHistory, currentPointer: pointer });
    setStorybook(editHistory.history[pointer]);
  };

  const SBStatus = storybook?.status;

  const state: StorybookState = {
    storybook: storybook || {},
    storybookInsightOverview: sbInsightOverview,
    storybookInsightSceneOverview: sbInsightSceneOverview,
    storybookInteractions: sbInsightInteractions,
    contentViewer,
    pageHistory,
    loadingStatus,
    currentPageIndex,
    undoRedo: {
      currentPointer: editHistory.currentPointer,
      historyLength: editHistory.history.length,
      canUndo: editHistory.currentPointer > 0,
      canRedo: editHistory.currentPointer < editHistory.history.length - 1
    },
    sbInsightCampaigns: sbInsightCampaign
  };

  const setState: StorybookSetState = {
    setPageHistory,
    setStorybook,
    setDataHasChanged,
    setContentViewer,
    setLoadingStatus,
    setCurrentPageIndex,
    setEditHistory
  };

  const entitlement: StorybookEntitlement = {
    allowEdit:
      SBStatus !== 'activated' &&
      SBStatus !== 'published' &&
      !layoutMode?.selectMode &&
      !layoutMode?.viewOnlyMode,
    allowSaveDraft:
      dataHasChanged &&
      storybook?.status !== 'activated' &&
      SBStatus !== 'published',
    allowPublish: SBStatus === 'draft' && !!storybook?.pages?.length,
    allowSortingPage:
      storybook?.pages?.length > 1 &&
      SBStatus !== 'activated' &&
      SBStatus !== 'published',
    allowDeleteStorybook: !layoutMode?.selectMode && !layoutMode?.viewOnlyMode,
    allowPreviewStorybook: !!storybook?.pages?.length,
    shouldShowActivatedOnly: layoutMode?.viewOnlyMode || layoutMode?.selectMode,
    ...layoutMode
  };

  const callService = <
    T extends SBServiceActionTarget,
    U extends SBServiceActionType<T>
  >(
    target: T,
    action: U,
    payload: SBServicePayloadType<T, U>,
    callback?: (response?: any) => void
  ) => {
    Error;
    callStorybookService(
      target,
      action,
      payload,
      state,
      setState,
      callback,
      () => {
        throwAlert(
          'Something wrong happen, please check internet connection.',
          'error'
        );
      }
    );
  };

  const shouldReloadInsightData = (
    params: InsightOverviewQuery,
    type: 'storybook' | 'scene' | 'interaction'
  ) => {
    let data: SBInsightOverview | SBInsightSceneOverview | any =
      sbInsightOverview.data || {};
    if (type === 'scene') {
      data = sbInsightSceneOverview.data || {};
    } else if (type === 'interaction') {
      data = sbInsightInteractions.data || {};
    }

    const _storybookId = data.storybookId;
    const _startDate = data.startDate;
    const _endDate = data.endDate;
    const _campaign = data.campaign;
    const { storybookId, campaign, endDate, startDate } = params;

    return (
      storybookId !== _storybookId ||
      campaign !== _campaign ||
      endDate !== _endDate ||
      startDate !== _startDate
    );
  };

  const getSbInsightCampaigns = (storybookId: string) => {
    const shouldLoadCampaign = sbInsightCampaign.storybookId !== storybookId;
    if (!shouldLoadCampaign) return Promise.resolve();
    return getStorybookCampaigns(storybookId).then((res) => {
      if (res.status === 200) {
        setSBInsightCampaign((pre) => ({
          ...pre,
          loading: false,
          data: res.data?.campaigns || [],
          storybookId
        }));
      }
    });
  };

  const getSbInsightOverviewData = (params: SBOverviewQuery, force = false) => {
    const shouldLoadCampaign =
      sbInsightCampaign.storybookId !== params.storybookId;
    const shouldLoadSbOverview = shouldReloadInsightData(params, 'storybook');
    if (!shouldLoadSbOverview && !shouldLoadCampaign && !force) {
      return;
    }

    const getCampaign = getSbInsightCampaigns(params.storybookId);
    const getOverview = getStoryBookOverview(params);

    setSBInsightOverview((pre) => ({ ...pre, loading: true }));
    return Promise.all([getCampaign, getOverview])
      ?.then(([_, res]) => {
        if (res.status === 200) {
          setSBInsightOverview((pre) => ({
            ...pre,
            loading: false,
            data: res.data
          }));
        }
      })
      .catch(() => {
        setSBInsightOverview((pre) => ({
          ...pre,
          loading: false,
          data: {}
        }));
      });
  };

  const getSBInsightSceneOverviewData = (
    params: SBInsightSceneOverviewQuery
  ) => {
    const shouldLoadSbSceneOverview = shouldReloadInsightData(params, 'scene');
    if (!shouldLoadSbSceneOverview) return Promise.resolve();

    setSBInsightSceneOverview((pre) => ({ ...pre, loading: true }));
    getSBInsightSceneOverview(params)
      .then((res) => {
        if (res.status === 200) {
          setSBInsightSceneOverview((pre) => ({
            ...pre,
            loading: false,
            data: res.data
          }));
        }
      })
      .catch(() => {
        setSBInsightSceneOverview((pre) => ({
          ...pre,
          loading: false,
          data: {}
        }));
      });
  };

  const getSbInsightInteractionData = (params: SBInsightInteractionQuery) => {
    const shouldLoadSbInteraction = shouldReloadInsightData(
      params,
      'interaction'
    );
    if (!shouldLoadSbInteraction) return Promise.resolve();

    setSBInsightInteractions((pre) => ({ ...pre, loading: true }));
    getSBInsightInteraction(params)
      .then((res) => {
        if (res.status === 200) {
          setSBInsightInteractions((pre) => ({
            ...pre,
            loading: false,
            data: res.data
          }));
        }
      })
      .catch(() => {
        setSBInsightInteractions((pre) => ({ ...pre, loading: false }));
      });
  };

  const currentPages = [...(storybook?.pages || [])];

  const resetState = () => {
    setPageHistory([]);
    setLayoutMode({});
    setStorybook({});
    setEditHistory(defaultEditHistory);
  };

  const resetStorybookOverview = () => {
    setSBInsightOverview({
      loading: false,
      data: {}
    })
  }

  const throwAlert = (message: string, type: 'error' | 'info') => {
    dispatch(
      (type === 'error' ? actionHubAlertError : actionHubAlertInfo)(message)
    );
  };

  const handleEditHistory = (data: IStorybook) => {
    const { currentPointer, history } = editHistory;
    const copy = [...history];

    const shouldCreateNewHistory = copy.length === 1 || !dataHasChanged;
    const shouldRemoveFirstHistory =
      copy.length === storybookEditHistoryStep + 1 && !dataHasChanged;

    if (shouldRemoveFirstHistory) {
      copy.shift();
    }
    const newPointer =
      shouldCreateNewHistory && !shouldRemoveFirstHistory
        ? currentPointer + 1
        : currentPointer;

    copy[newPointer] = data;
    setEditHistory({
      currentPointer: newPointer,
      history: copy.filter((_, index) => index <= newPointer)
    });
  };

  const updateStorybook = (
    updatedStorybook: IStorybook,
    append?: boolean,
    doNotFlagChange?: boolean
  ) => {
    setDataHasChanged(!doNotFlagChange);
    const newState = append
      ? { ...storybook, ...updatedStorybook }
      : updatedStorybook;

    setStorybook(newState);
    handleEditHistory(newState);
  };

  const handleReadAction = (
    target: SBActionTarget | SBServiceActionTarget,
    payload?: any,
    callback?: (response?: any) => any
  ) => {
    switch (target) {
      case SBActionTarget.ActivatedStorybooks:
      case SBActionTarget.Storybooks:
        callService(
          target as SBServiceActionTarget,
          SBServiceAction.Read,
          {
            userId,
            brandId: brandSetup?.parentBrandId || brandId,
            keywords: payload?.keywords,
            startIndex: payload?.startIndex,
            excludeMine: payload?.excludeMine,
            teamIds: payload?.teamIds
          },
          (response: { storybooks: IStorybook[]; totalCount: number }) => {
            callback?.(response);
            dispatch(
              addUserInfoAsync(
                response.storybooks
                  .map((storybook) => [
                    storybook.createdBy,
                    storybook.modifiedBy
                  ])
                  .flat()
              )
            );
          }
        );
        break;

      case SBActionTarget.Storybook:
        callService(
          target as SBServiceActionTarget,
          SBServiceAction.Read,
          payload,
          (res) => {
            callback?.(res);
            dispatch(addUserInfoAsync([res.createdBy, res.modifiedBy]));
          }
        );
        break;
      default:
        callService(
          target as SBServiceActionTarget,
          SBServiceAction.Read,
          payload,
          callback
        );
    }
  };

  const handlePublishStorybook = (
    payload: SavePublishedStorybookPayload,
    callback?: any
  ) => {
    callService(
      SBActionTarget.PublishedStorybook,
      SBServiceAction.Save,
      {
        id: payload.storybook.id,
        publishedBy: userId,
        passcode: payload.passcode
      },
      () => {
        callback?.();
      }
    );
  };

  const handleUnpublishStorybook = (payload: IStorybook, callback?: any) => {
    callService(
      SBActionTarget.PublishedStorybook,
      SBServiceAction.Delete,
      payload.id,
      () => {
        callback?.();
      }
    );
  };

  const handleSaveAction = (
    target:
      | SBActionTarget.Storybook
      | SBActionTarget.StorybookAttributes
      | SBActionTarget.ActivatedStorybook
      | SBActionTarget.PublishedStorybook,
    callback?: (response?: any) => any,
    payload?: any
  ) => {
    switch (target) {
      case SBActionTarget.StorybookAttributes: {
        callService(
          target,
          SBServiceAction.Save,
          payload || {
            id: storybook.id,
            tags: storybook.tags,
            visibility: storybook.visibility,
            visibilityScope: storybook.visibilityScope
          },
          callback
        );
        break;
      }
      case SBActionTarget.Storybook: {
        const request = mapStorybookToPayload(storybook, userId, brandId);
        callService(target, SBServiceAction.Save, request, callback);
        saveEditorJson({
          id: storybook.id,
          version: storybook.version,
          json: getPolotnoJsonFromStorybook(storybook)
        });
        break;
      }
      case SBActionTarget.ActivatedStorybook: {
        if (!storybook?.title?.trim() || !storybook?.pages?.length) {
          throwAlert('Storybook is missing title or content', 'error');
        } else {
          callService(
            target,
            SBServiceAction.Save,
            {
              id: storybook.id,
              activatedBy: userId
            },
            () => {
              const cb = () => {
                dispatch(
                  actionHubAlertInfo('Your Storybook has been activated!')
                );
                handleNavigate();
              };
              if (payload?.publishAfterActivated) {
                callService(
                  SBActionTarget.PublishedStorybook,
                  SBServiceAction.Save,
                  {
                    id: storybook.id,
                    publishedBy: userId
                  },
                  cb
                );
              } else {
                cb();
              }
            }
          );
        }

        break;
      }

      case SBActionTarget.PublishedStorybook: {
        handlePublishStorybook(payload, callback);
        break;
      }
    }
  };

  const handleUpdateAction = <T extends SBActionTarget>(
    target: T,
    payload?: UpdateActionPayload<T>
  ) => {
    switch (target) {
      case SBActionTarget.Storybook: {
        updateStorybook(
          payload.data as IStorybook,
          payload.append,
          payload.doNotFlagChange
        );
        break;
      }

      case SBActionTarget.StorybookPage: {
        const { index, page, doNotFlagChange } = payload;
        const copyPages = [...currentPages];
        copyPages[index] = { ...page, hasChanged: !doNotFlagChange };
        updateStorybook({ pages: copyPages }, true);
        break;
      }
      default:
        return;
    }
  };

  const handleDeleteAction = (
    target: SBActionTarget | SBServiceActionTarget,
    payload: any,
    callback?: any
  ) => {
    switch (target) {
      case SBActionTarget.Storybook:
        callService(
          SBActionTarget.Storybook,
          SBServiceAction.Delete,
          payload,
          callback
        );
        break;

      case SBActionTarget.StorybookPage:
        updateStorybook(
          {
            pages: currentPages.filter((_, i) => i !== payload)
          },
          true
        );
        break;

      case SBActionTarget.PublishedStorybook:
        handleUnpublishStorybook(payload, callback);
        break;
    }
  };

  const openPage = (page: StoryBookLayoutPage) => {
    setPageHistory([...pageHistory, page]);
  };

  const handleOpenPage = (page: StoryBookLayoutPage, payload: any) => {
    const actionReadTarget = entitlement.shouldShowActivatedOnly
      ? SBActionTarget.ActivatedStorybook
      : SBActionTarget.Storybook;

    const actionPayloadId = entitlement.shouldShowActivatedOnly
      ? payload?.id
      : { id: payload?.id };

    const hasPayloadId = actionPayloadId?.id || payload?.id;

    switch (page) {
      case StoryBookLayoutPage.SEARCH: {
        setStorybook({});
        setContentViewer({});
        setDataHasChanged(undefined);
        setCurrentPageIndex(null);
        setPageHistory([StoryBookLayoutPage.SEARCH]);
        setLoadingStatus({});
        break;
      }
      case StoryBookLayoutPage.PREVIEW: {
        const callBack = () => openPage(StoryBookLayoutPage.PREVIEW);
        if (hasPayloadId) {
          handleReadAction(
            SBActionTarget.ActivatedStorybook,
            payload?.id,
            callBack
          );
        } else {
          callBack();
        }
        break;
      }
      case StoryBookLayoutPage.SORTER: {
        if (hasPayloadId) {
          handleReadAction(actionReadTarget, actionPayloadId);
        }
        openPage(StoryBookLayoutPage.SORTER);
        break;
      }

      case StoryBookLayoutPage.DESIGNER: {
        const callBack = () => {
          openPage(StoryBookLayoutPage.DESIGNER);
          handleReadAction(SBActionTarget.ActivatedStorybooks, userId);
        };
        setContentViewer(null);
        if (hasPayloadId) {
          handleReadAction(actionReadTarget, actionPayloadId, callBack);
          return;
        }
        if (payload?.newPage) {
          const storybookId = generateV4UUID();
          const newStorybook = {
            id: storybookId,
            pages: [],
            settings: defaultStorybookSettings,
            ...(payload?.initData || {})
          };
          updateStorybook(newStorybook);
          setLoadingStatus({ [storybookId]: LoadingStatus.LOADED });
          setEditHistory({
            currentPointer: 0,
            history: [newStorybook]
          });
        }
        callBack();
        break;
      }
    }
  };
  const closePage = () => {
    const copy = [...pageHistory];
    copy.pop();
    setPageHistory(copy);
  };

  const handleClosePage = () => {
    const currentPage = last(pageHistory);
    const pageAfterClose = first(takeRight(pageHistory, 2));

    if (currentPage === StoryBookLayoutPage.DESIGNER) {
      closePage();
      setStorybook({});
      setContentViewer({});
      setCurrentPageIndex(null);
      setDataHasChanged(undefined);
    } else if (
      currentPage === StoryBookLayoutPage.PREVIEW &&
      pageAfterClose !== StoryBookLayoutPage.DESIGNER
    ) {
      setStorybook({});
      closePage();
    } else {
      closePage();
    }
  };

  const handleNavigate = (
    page?: StoryBookLayoutPage,
    open?: boolean,
    payload?: any
  ) => {
    if (open) {
      handleOpenPage(page, payload);
    } else {
      handleClosePage();
    }
  };

  const handleUndo = () => {
    const newPointer = editHistory.currentPointer - 1;
    if (newPointer >= 0) {
      updateHistoryPointer(newPointer);
      setDataHasChanged(false);
    }
  };

  const handleRedo = () => {
    const newPointer = editHistory.currentPointer + 1;
    if (newPointer < editHistory.history.length) {
      updateHistoryPointer(newPointer);
      setDataHasChanged(false);
    }
  };

  const action: StorybookAction = {
    read: handleReadAction,
    save: handleSaveAction,
    update: handleUpdateAction,
    delete: handleDeleteAction,
    navigate: handleNavigate,
    setLayoutMode,
    openStorybookPage: setCurrentPageIndex,
    resetState,
    undo: handleUndo,
    redo: handleRedo,
    setStorybook,
    resetStorybookOverview,
  };

  const visibility = {
    showSortButton: SBStatus !== 'published',
    showPublishButton: SBStatus && SBStatus === 'draft',
    showSaveDraftButton: SBStatus !== 'published',
    showDeleteStorybookButton: true
  };

  return (
    <StoryBookContext.Provider
      value={{
        setContentViewer,
        state,
        action,
        entitlement,
        visibility,
        selectedStorybookPage: currentPages[currentPageIndex],
        getSbInsightOverviewData,
        getSBInsightSceneOverviewData,
        getSbInsightInteractionData
      }}
    >
      {children}
    </StoryBookContext.Provider>
  );
};

export default SBContextContainer;
