import numeral from 'numeral';
import humanizeDuration from 'humanize-duration';
export const formattedNumber = (number, format = '0,0') => {
  return numeral(number).format(format);
};

export const formatDurationFromMiliSeconds = (seconds: number) => {
  return (
    humanizeDuration(
      seconds, // convert to milliseconds
      {
        units: ['h', 'm', 's'],
        conjunction: '',
        delimiter: ' ',
        round: true,
        language: 'shortEn',
        languages: {
          shortEn: {
            y: () => 'y',
            mo: () => 'mo',
            w: () => 'w',
            d: () => 'd',
            h: () => 'h',
            m: () => 'm',
            s: () => 's',
            ms: () => 'ms'
          }
        }
      }
    )
      //removes all spaces
      .replace(/\s/g, '')
      //adds space between number and unit
      .replace(/[^0-9](?=[0-9])/g, '$& ')
  );
};
