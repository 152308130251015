import {
  UPDATING_STORYBOOK_ATTRIBUTES,
  UPDATING_STORYBOOK_ATTRIBUTES_FAILED,
  UPDATING_STORYBOOK_ATTRIBUTES_SUCCESSFUL
} from './../../../utils/constants';
import { logEvent } from '../../../analytics';
import { ITagsVisibilityPayload, LoadingStatus } from '../../../interfaces';
import { IStorybookPayload } from '../../../mappers/storybook';
import {
  ACTIVATING_STORYBOOK,
  ACTIVATING_STORYBOOK_FAILED,
  ACTIVATING_STORYBOOK_SUCCESSFUL,
  DELETING_STORYBOOK,
  DELETING_STORYBOOK_FAILED,
  DELETING_STORYBOOK_SUCCESSFUL,
  LOADING_STORYBOOK,
  LOADING_STORYBOOKS,
  LOADING_STORYBOOKS_FAILED,
  LOADING_STORYBOOKS_SUCCESSFUL,
  LOADING_STORYBOOK_FAILED,
  LOADING_STORYBOOK_SUCCESSFUL,
  PUBLISHING_STORYBOOK,
  PUBLISHING_STORYBOOK_FAILED,
  PUBLISHING_STORYBOOK_SUCCESSFUL,
  SAVING_STORYBOOK,
  SAVING_STORYBOOK_FAILED,
  SAVING_STORYBOOK_SUCCESSFUL,
  UNPUBLISHING_STORYBOOK,
  UNPUBLISHING_STORYBOOK_FAILED,
  UNPUBLISHING_STORYBOOK_SUCCESSFUL
} from '../../../utils/constants';
import {
  activateStorybook,
  deleteStorybookById,
  getActivatedStorybookById,
  getPublishedStorybookById,
  getStorybookById,
  getStorybookByIdAndVersion,
  IStorybooksSearchParam,
  publishStorybook,
  saveStorybook,
  searchForActivatedAndPublishedStorybooks,
  searchForStorybooks,
  unpublishStorybook,
  updateStorybookAttributes
} from '../../clientSideServices/storybook';
import { StorybookSetState, StorybookState } from './SBContextContainer';

export enum SBActionTarget {
  Storybooks = 'Target.Storybooks', //string
  ActivatedStorybooks = 'Target.ActivatedStorybooks', //string
  Storybook = 'Target.Storybook', //string
  StorybookAttributes = 'Target.StorybookAttributes',
  ActivatedStorybook = 'Target.ActivatedStorybook', //string
  PublishedStorybook = 'Target.PublishedStorybook', //string
  StorybookSetting = 'Target.StorybookSetting', //null
  StorybookPages = 'Target.StorybookPages', //null
  StorybookPage = 'Target.StorybookPage', //number
  StorybookPageSetting = 'Target.StorybookPageSetting', //number
  StorybookOfVersion = 'Target.StorybookOfVersion', // udah
  StorybookFileContent = 'Target.StorybookFileContent' //string
}

export enum SBAction {
  Read = 'Action.Read',
  Update = 'Action.Update',
  Delete = 'Action.Delete'
}

export type SBServiceActionTarget =
  | SBActionTarget.Storybooks
  | SBActionTarget.ActivatedStorybooks
  | SBActionTarget.PublishedStorybook
  | SBActionTarget.Storybook
  | SBActionTarget.ActivatedStorybook
  | SBActionTarget.StorybookOfVersion
  | SBActionTarget.StorybookAttributes;

export enum SBServiceAction {
  Save = 'ServiceAction.Save',
  Read = 'ServiceAction.Read',
  Delete = 'ServiceAction.Delete'
}

export type SBReadPayloadType<T extends SBActionTarget> =
  T extends SBActionTarget.StorybookOfVersion
    ? { id: string; version: number }
    : string | number;

export type SBServiceActionType<T extends SBServiceActionTarget> =
  T extends SBActionTarget.Storybook
    ? SBServiceAction
    : T extends SBActionTarget.ActivatedStorybook
    ? SBServiceAction.Read | SBServiceAction.Save
    : T extends SBActionTarget.PublishedStorybook
    ? SBServiceAction.Read | SBServiceAction.Save | SBServiceAction.Delete
    : T extends SBActionTarget.StorybookAttributes
    ? SBServiceAction.Save
    : SBServiceAction.Read;

export type SBServicePayloadType<
  T extends SBServiceActionTarget,
  U extends SBServiceActionType<T>
> = T extends SBActionTarget.Storybook
  ? U extends SBServiceAction.Save
    ? IStorybookPayload
    : U extends SBServiceAction.Read
    ? { id: string; silent?: boolean }
    : string
  : T extends SBActionTarget.ActivatedStorybook
  ? U extends SBServiceAction.Save
    ? { id: string; activatedBy: string; silent?: boolean }
    : string
  : T extends SBActionTarget.PublishedStorybook
  ? U extends SBServiceAction.Save
    ? { id: string; publishedBy: string; passcode?: string }
    : U extends SBServiceAction.Read
    ? { id: string; passcode?: string }
    : string
  : T extends SBActionTarget.StorybookOfVersion
  ? { id: string; version: number }
  : T extends SBActionTarget.Storybooks
  ? IStorybooksSearchParam
  : T extends SBActionTarget.ActivatedStorybooks
  ? IStorybooksSearchParam
  : T extends SBActionTarget.StorybookAttributes
  ? ITagsVisibilityPayload
  : string;

const getService = <
  T extends SBServiceActionTarget,
  U extends SBServiceActionType<T>
>(
  type: T,
  action: U
) => {
  switch (type) {
    case SBActionTarget.Storybooks:
      if (action === SBServiceAction.Read) return searchForStorybooks;
      break;
    case SBActionTarget.ActivatedStorybooks:
      if (action === SBServiceAction.Read)
        return searchForActivatedAndPublishedStorybooks;
      break;
    case SBActionTarget.StorybookOfVersion:
      if (action === SBServiceAction.Read) return getStorybookByIdAndVersion;
      break;
    case SBActionTarget.ActivatedStorybook: {
      if (action === SBServiceAction.Read) return getActivatedStorybookById;
      if (action === SBServiceAction.Save) return activateStorybook;
      break;
    }
    case SBActionTarget.Storybook: {
      if (action === SBServiceAction.Save) return saveStorybook;
      if (action === SBServiceAction.Delete) return deleteStorybookById;
      if (action === SBServiceAction.Read)
        return (payload) => getStorybookById(payload.id);
      break;
    }
    case SBActionTarget.PublishedStorybook: {
      if (action === SBServiceAction.Read) return getPublishedStorybookById;
      if (action === SBServiceAction.Save) return publishStorybook;
      if (action === SBServiceAction.Delete) return unpublishStorybook;
      break;
    }
    case SBActionTarget.StorybookAttributes: {
      if (action === SBServiceAction.Save) return updateStorybookAttributes;
      break;
    }
    default:
      undefined;
  }
};

const handleInit = <
  T extends SBServiceActionTarget,
  U extends SBServiceActionType<T>
>(
  target: T,
  action: U,
  payload: SBServicePayloadType<T, U>,
  setState: React.Dispatch<
    React.SetStateAction<{ [key: string]: LoadingStatus }>
  >
) => {
  const logInit = (event: string, payload: any, loadingKey?: string) => {
    logEvent(event, event, payload);
    if (loadingKey) {
      setState({ [loadingKey]: LoadingStatus.LOADING });
    }
  };

  switch (target) {
    case SBActionTarget.Storybooks: {
      if (action === SBServiceAction.Read) {
        logInit(LOADING_STORYBOOKS, payload, 'any');
      }
      break;
    }

    case SBActionTarget.ActivatedStorybooks: {
      if (action === SBServiceAction.Read) {
        logInit(
          LOADING_STORYBOOKS,
          { ...(payload as IStorybooksSearchParam), activatedOnly: true },
          'any'
        );
      }
      break;
    }

    case SBActionTarget.Storybook: {
      if (action === SBServiceAction.Read) {
        const typedPayload = payload as SBServicePayloadType<
          SBActionTarget.Storybook,
          SBServiceAction.Read
        >;
        logInit(
          LOADING_STORYBOOK,
          { storybookId: typedPayload.id },
          typedPayload.id
        );
      }
      if (action === SBServiceAction.Delete) {
        logInit(
          DELETING_STORYBOOK,
          { storybookId: payload },
          payload as string
        );
      }
      if (action === SBServiceAction.Save) {
        const evtPayload: IStorybookPayload = {
          ...(payload as IStorybookPayload)
        };
        delete evtPayload.settings;
        delete evtPayload.pages;
        logInit(
          SAVING_STORYBOOK,
          evtPayload,
          (payload as IStorybookPayload).id
        );
      }
      break;
    }

    case SBActionTarget.ActivatedStorybook: {
      if (action === SBServiceAction.Read) {
        logInit(
          LOADING_STORYBOOK,
          {
            storybookId: payload,
            activatedOnly: true
          },
          payload as string
        );
      }
      if (action === SBServiceAction.Save) {
        const request = payload as { id: string; activatedBy: string };
        logInit(ACTIVATING_STORYBOOK, request, request.activatedBy);
      }
      break;
    }

    case SBActionTarget.PublishedStorybook: {
      const request = payload as { id: string; passcode?: string };
      if (action === SBServiceAction.Read) {
        logInit(
          LOADING_STORYBOOK,
          {
            storybookId: request.id,
            publishedOnly: true
          },
          request.id
        );
      }
      if (action === SBServiceAction.Save) {
        const request = payload as {
          id: string;
          publishedBy: string;
          passcode?: string;
        };
        logInit(PUBLISHING_STORYBOOK, {
          id: request.id,
          publishedBy: request.publishedBy
        });
      }
      if (action === SBServiceAction.Delete) {
        logInit(UNPUBLISHING_STORYBOOK, { storybookId: payload });
      }
      break;
    }
    case SBActionTarget.StorybookOfVersion: {
      logInit(
        LOADING_STORYBOOK,
        payload,
        (payload as { id: string; version: number }).id
      );
      break;
    }

    case SBActionTarget.StorybookAttributes: {
      if (action === SBServiceAction.Save) {
        logInit(
          UPDATING_STORYBOOK_ATTRIBUTES,
          payload,
          (payload as ITagsVisibilityPayload).id
        );
      }
      break;
    }
  }
};

const handleSuccess = <
  T extends SBServiceActionTarget,
  U extends SBServiceActionType<T>
>(
  response: any,
  target: T,
  action: U,
  payload: SBServicePayloadType<T, U>,
  state: StorybookState,
  setState: StorybookSetState
) => {
  const logSuccess = (event: string, payload: any, loadingKey?: string) => {
    logEvent(event, event, payload);
    if (loadingKey) {
      setState.setLoadingStatus({ [loadingKey]: LoadingStatus.LOADED });
    }
  };

  switch (target) {
    case SBActionTarget.Storybooks: {
      if (action === SBServiceAction.Read) {
        logSuccess(LOADING_STORYBOOKS_SUCCESSFUL, payload, 'any');
      }
      break;
    }

    case SBActionTarget.ActivatedStorybooks: {
      if (action === SBServiceAction.Read) {
        logSuccess(
          LOADING_STORYBOOKS_SUCCESSFUL,
          { ...(payload as IStorybooksSearchParam), activatedOnly: true },
          'any'
        );
      }
      break;
    }

    case SBActionTarget.Storybook: {
      if (action === SBServiceAction.Read) {
        const typedPayload = payload as SBServicePayloadType<
          SBActionTarget.Storybook,
          SBServiceAction.Read
        >;
        setState.setEditHistory({
          currentPointer: 0,
          history: [{ ...response, status: 'draft' }]
        });

        if (!typedPayload.silent) {
          setState.setStorybook(response);
        }

        logSuccess(
          LOADING_STORYBOOK_SUCCESSFUL,
          { storybookId: typedPayload.id },
          typedPayload.id
        );
      }
      if (action === SBServiceAction.Delete) {
        logSuccess(
          DELETING_STORYBOOK_SUCCESSFUL,
          { storybookId: payload },
          'any'
        );
      }
      if (action === SBServiceAction.Save) {
        const evtPayload: IStorybookPayload = {
          ...(payload as IStorybookPayload)
        };
        delete evtPayload.settings;
        delete evtPayload.pages;
        setState.setDataHasChanged(false);
        logSuccess(
          SAVING_STORYBOOK_SUCCESSFUL,
          evtPayload,
          (payload as IStorybookPayload).id
        );
      }
      break;
    }

    case SBActionTarget.ActivatedStorybook: {
      if (action === SBServiceAction.Read) {
        setState.setStorybook(response);
        logSuccess(
          LOADING_STORYBOOK_SUCCESSFUL,
          {
            storybookId: payload,
            activatedOnly: true
          },
          payload as string
        );
      }
      if (action === SBServiceAction.Save) {
        logSuccess(
          ACTIVATING_STORYBOOK_SUCCESSFUL,
          payload,
          (payload as { id: string; activatedBy: string }).activatedBy
        );
      }
      break;
    }

    case SBActionTarget.PublishedStorybook: {
      if (action === SBServiceAction.Read) {
        const request = payload as { id: string; passcode?: string };
        logSuccess(
          LOADING_STORYBOOK_SUCCESSFUL,
          {
            storybookId: request.id,
            publishedOnly: true
          },
          request.id
        );
      }
      if (action === SBServiceAction.Save) {
        const request = payload as {
          id: string;
          publishedBy: string;
          passcode?: string;
        };
        logSuccess(PUBLISHING_STORYBOOK_SUCCESSFUL, {
          id: request.id,
          publishedBy: request.publishedBy
        });
      }
      if (action === SBServiceAction.Delete) {
        logSuccess(UNPUBLISHING_STORYBOOK_SUCCESSFUL, { storybookId: payload });
      }
      break;
    }
    case SBActionTarget.StorybookOfVersion: {
      setState.setStorybook(response);
      logSuccess(
        LOADING_STORYBOOK_SUCCESSFUL,
        payload,
        (payload as { id: string; version: number }).id
      );
      break;
    }

    case SBActionTarget.StorybookAttributes: {
      if (action === SBServiceAction.Save) {
        logSuccess(
          UPDATING_STORYBOOK_ATTRIBUTES_SUCCESSFUL,
          payload,
          (payload as ITagsVisibilityPayload).id
        );
      }
      break;
    }
  }
};

const handleError = <
  T extends SBServiceActionTarget,
  U extends SBServiceActionType<T>
>(
  error: any,
  target: T,
  action: U,
  payload: SBServicePayloadType<T, U>,
  state?: StorybookState,
  setState?: StorybookSetState
) => {
  const logFailed = (event: string, payload: any, loadingKey?: string) => {
    logEvent(event, event, { ...payload, error: error });
    if (loadingKey) {
      setState.setLoadingStatus({ [loadingKey]: LoadingStatus.FAILED });
    }
  };

  switch (target) {
    case SBActionTarget.Storybooks: {
      if (action === SBServiceAction.Read) {
        logFailed(LOADING_STORYBOOKS_FAILED, payload, 'any');
      }
      break;
    }

    case SBActionTarget.ActivatedStorybooks: {
      if (action === SBServiceAction.Read) {
        logFailed(
          LOADING_STORYBOOKS_FAILED,
          { ...(payload as IStorybooksSearchParam), activatedOnly: true },
          'any'
        );
      }
      break;
    }

    case SBActionTarget.Storybook: {
      if (action === SBServiceAction.Read) {
        const typedPayload = payload as SBServicePayloadType<
          SBActionTarget.Storybook,
          SBServiceAction.Read
        >;
        logFailed(
          LOADING_STORYBOOK_FAILED,
          { storybookId: typedPayload.id },
          typedPayload.id
        );
      }
      if (action === SBServiceAction.Delete) {
        logFailed(
          DELETING_STORYBOOK_FAILED,
          { storybookId: payload },
          payload as string
        );
      }
      if (action === SBServiceAction.Save) {
        const evtPayload: IStorybookPayload = {
          ...(payload as IStorybookPayload)
        };
        delete evtPayload.settings;
        delete evtPayload.pages;
        logFailed(
          SAVING_STORYBOOK_FAILED,
          { payload: evtPayload },
          (payload as IStorybookPayload).id
        );
      }
      break;
    }

    case SBActionTarget.ActivatedStorybook: {
      if (action === SBServiceAction.Read) {
        logFailed(
          LOADING_STORYBOOK_FAILED,
          {
            storybookId: payload,
            activatedOnly: true
          },
          payload as string
        );
      }
      if (action === SBServiceAction.Save) {
        const request = payload as { id: string; activatedBy: string };
        logFailed(ACTIVATING_STORYBOOK_FAILED, request, request.activatedBy);
      }
      break;
    }

    case SBActionTarget.PublishedStorybook: {
      if (action === SBServiceAction.Read) {
        logFailed(
          LOADING_STORYBOOK_FAILED,
          {
            storybookId: (payload as { id: string }).id,
            publishedOnly: true
          },
          (payload as { id: string }).id
        );
      }
      if (action === SBServiceAction.Save) {
        const request = payload as { id: string; activatedBy: string };
        logFailed(PUBLISHING_STORYBOOK_FAILED, request);
      }
      if (action === SBServiceAction.Delete) {
        logFailed(UNPUBLISHING_STORYBOOK_FAILED, { storybookId: payload });
      }
      break;
    }

    case SBActionTarget.StorybookOfVersion: {
      const request = payload as { id: string; version: number };
      logFailed(LOADING_STORYBOOK_FAILED, request, request.id);
      break;
    }

    case SBActionTarget.StorybookAttributes: {
      if (action === SBServiceAction.Save) {
        logFailed(
          UPDATING_STORYBOOK_ATTRIBUTES_FAILED,
          payload,
          (payload as ITagsVisibilityPayload).id
        );
      }
      break;
    }
  }
};

export const callStorybookService = <
  T extends SBServiceActionTarget,
  U extends SBServiceActionType<T>
>(
  target: T,
  action: U,
  payload: SBServicePayloadType<T, U>,
  state: StorybookState,
  setState: StorybookSetState,
  callBack?: (response?: any) => any,
  failedCallBack?: () => void
) => {
  const service = getService(target, action);
  handleInit(target, action, payload, setState.setLoadingStatus);

  if (service) {
    service(payload as any)
      .then((response) => {
        handleSuccess(response, target, action, payload, state, setState);
        callBack?.(response);
      })
      .catch((e) => {
        handleError(e, target, action, payload, state, setState);
        failedCallBack?.();
      });
  } else {
    handleError('Service not found', target, action, payload, state, setState);
  }
};
