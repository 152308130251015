import React from 'react';
import { StatsValueType } from './helpers';
import { Modal } from 'react-bootstrap';
import {
  getAnalyticEvent,
  logCustomAnalytics,
  logEvent
} from '../../../../analytics';
import { getCurrentHost } from '../../../../utils/window';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import { chunk } from 'lodash';
import { AnalyticEvent } from '../../../../interfaces';
import ReactDatePicker from 'react-datepicker';
import { enGB } from 'date-fns/locale';
const selectStyles = {
  placeholder: (base) => ({
    ...base,
    fontSize: '14px'
  }),
  control: (base) => ({
    ...base,
    minHeight: '35px',
    width: '100%',
    borderWidth: '1px',
    boxShadow: 'none',
    borderColor: '#000'
  }),
  menu: (base) => ({
    ...base,
    fontSize: '14px',
    top: 0
  }),
  menuPortal: (base) => ({
    ...base,
    height: '300px',
    zIndex: 9999
  }),
  option: (base) => ({
    ...base
  }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: '0 5px'
  })
};

const statsFormMapper = {
  [StatsValueType.TARGET]: {
    title: 'Set Target',
    inputLabel: 'Number of Target'
  },
  [StatsValueType.DELIVERED]: {
    title: 'Set Delivered',
    inputLabel: 'Number of Delivered'
  },
  [StatsValueType.CONVERSION]: {
    title: 'Register Sales',
    inputLabel: 'Sales Price'
  }
};

function EditStatsModal({
  type,
  enabled,
  onHide,
  storybookId
}: {
  type: StatsValueType;
  enabled: boolean;
  onHide: () => void;
  storybookId: string;
}) {
  const [updating, setUpdating] = React.useState<boolean>(false);

  const [value, setValue] = React.useState(1);
  const [country, setCountry] = React.useState('SG');
  const [date, setDate] = React.useState(new Date());

  const metadata = React.useMemo(() => {
    const data =
      statsFormMapper[type] || statsFormMapper[StatsValueType.TARGET];
    return {
      title: data.title,
      inputLabel: data.inputLabel
    };
  }, [type]);

  const countries = React.useMemo(() => countryList().getData(), []);

  const getPayloadByType = (type: StatsValueType) => {
    const eventTimestamp = date.getTime();
    const url = `${getCurrentHost()}/storybook/${storybookId}`;
    if (type === StatsValueType.CONVERSION) {
      return {
        salesPrice: value,
        currency: 'USD',
        country: country,
        uuid: storybookId,
        event_timestamp: eventTimestamp,
        url
      };
    }
    if (type === StatsValueType.DELIVERED) {
      return {
        delivered: value,
        uuid: storybookId,
        country: country,
        event_timestamp: eventTimestamp,
        url
      };
    }
    if (type === StatsValueType.TARGET) {
      return {
        target: value,
        uuid: storybookId,
        country: country,
        event_timestamp: eventTimestamp,
        url
      };
    }
  };

  const handleOnSave = () => {
    const payload = getPayloadByType(type);
    setUpdating(true);
    if (type !== StatsValueType.CONVERSION) {
      const event = getAnalyticEvent(type, payload);
      const events: AnalyticEvent[] = [];
      for (let i = 0; i < value; i++) {
        events.push(event);
      }
      const eventChunks = chunk(events, 50);
      eventChunks.forEach((chunk) => logCustomAnalytics(chunk));
    } else logEvent(type, type, payload, true);
    setTimeout(() => {
      setUpdating(false);
      onHide();
    }, 1000);
  };

  const shouldDisableSaveBtn = !value || updating;

  return (
    <>
      <Modal
        show={enabled}
        onHide={onHide}
        centered
        className="modal-edit-stats"
      >
        <div className="modal-stats-body">
          <h3 className="mb-3">{metadata.title}</h3>
          <div className="input-container">
            <label className="input-label">{metadata.inputLabel}</label>
            <input
              className="form-control"
              type="number"
              name="value"
              value={value}
              onChange={(e) => setValue(e.target.valueAsNumber)}
            />
          </div>
          <div className="input-container">
            <label htmlFor="date" className="input-label">
              Date
            </label>
            <ReactDatePicker
              selected={date}
              onChange={setDate}
              dropdownMode="select"
              locale={enGB}
              className="calendar"
              useWeekdaysShort={true}
              isClearable={false}
              customInput={<input className="form-control" />}
              showTimeInput
            />
          </div>
          <div className="input-container">
            <label className="input-label">Country</label>
            <Select
              inputId="search"
              isSearchable
              menuPosition="fixed"
              menuPlacement="bottom"
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: '#e2e2e2',
                  primary: '#000'
                }
              })}
              minMenuHeight={250}
              menuPortalTarget={document.body}
              classNamePrefix="countrySelect"
              getOptionLabel={(option: any) =>
                option.value + ' - ' + option.label
              }
              defaultValue={() => countries.find((c) => c.value === 'SG')}
              styles={selectStyles}
              data-lpignore="true"
              options={countries}
              onChange={(option) => {
                setCountry(option.value);
              }}
            />
          </div>
          <div className="btn-container mt-3">
            <button
              className="btn btn-light btn-rounded btn-outline"
              onClick={onHide}
            >
              Cancel
            </button>
            <button
              id="save-button"
              disabled={shouldDisableSaveBtn}
              className="btn btn-dark btn-rounded"
              onClick={handleOnSave}
            >
              {updating ? 'Saving...' : 'Save'}
            </button>
          </div>
        </div>
      </Modal>
      <style jsx global>{`
        .modal-stats-body {
          padding: 30px;
          display: flex;
          flex-direction: column;
          gap: 20px;
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
        }
        .modal-stats-body label {
          font-weight: 500;
        }
        .modal-stats-body input,
        .modal-stats-body select {
          border: 1px solid #000;
          outline: none;
        }
        .modal-stats-body .btn-container {
          display: flex;
          gap: 15px;
          align-items: center;
        }
        .modal-stats-body .btn-container .btn {
          flex: 1;
        }
        .modal-stats-body .btn-rounded {
          border-radius: 20px;
          padding: 5px 20px;
        }
        .modal-stats-body .btn-outline {
          background-color: #fff;
          color: #000;
          border-color: #000;
          border-width: 1px;
        }
        .modal-stats-body .btn-dark {
          background-color: #000;
          color: #fff;
        }
        .input-container {
          display: flex;
          flex-direction: column;
        }
        input.react-datepicker-time__input {
          display: inline-block;
          width: 100% !important;
          border: 1px solid #000;
          border-radius: 5px;
          padding: 5px;
        }
      `}</style>
    </>
  );
}

export default EditStatsModal;
